import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Form = ( {cat} ) => {
  const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/plans.php?catID=${cat}`)
            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        };
    }, []);

    console.log(pagedata);
    if (pagedata.length === 0) {
        console.log('no data');
        // return null;
    }

    const [user_mobile, setusermobile] = useState();
    const [plans, setPlans] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const ref_mobile = useRef(null);

    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        axios.get(window.weburl + `api/get_plans.php?cid=${cat}`)
            .then(response => setPlans(response.data))
            .catch(error => console.log(error));
    }, []);
    useEffect(() => {
        axios.get(window.weburl + 'api/get_states.php')
            .then(response => setStates(response.data))
            .catch(error => console.log(error));
    }, []);

    function getCities(state) {
        axios.get(window.weburl + `api/get_cities.php?sid=${state}`)
            .then(response => setCities(response.data))
            .catch(error => console.log(error));
    }

    const handledisable = () => {
        if (isValidPhone(user_mobile) && (user_mobile)) {
            const element = document.getElementById('submit_button_text');
            element.classList.remove('disabled');
            setIsDisabled(false);
        } else {
            const element = document.getElementById('submit_button_text');
            element.classList.add('disabled');
            setIsDisabled(true);
        }
    };

    function isValidPhone(phone) {
        const regex = /^[6-9]\d{9}$/gi;
        return regex.test(phone);
    }
    // alert(pagedata.catID);
    const handleSubmit = () => {
        if (!isValidPhone(user_mobile)) {
            alert("Please enter Mobile number");
            ref_mobile.current.focus();
            return false;
        } else {
            document.getElementById("submit_button_text").innerText='SUBMITTING...';
            const url = window.weburl + "api/insert_hook.php";
            let fData = new FormData();
            fData.append('user_phone', user_mobile);
            fData.append('catid', cat);
            fData.append('pid', selectedPlan);
            fData.append('user_state', selectedState);
            fData.append('user_city', selectedCity);
            fData.append('td_name', 'androidtv');
            fData.append('utm_url', window.location.href);
            axios.post(url, fData)
                .then(response => alert(response.data))
                .catch(error => alert(error));
            document.getElementById("submit_button_text").innerText='SUBMIT';
            document.getElementById("user_mobile").value='';
            document.getElementById("selectedPlan").value='';
            document.getElementById("selectedState").value='';
            document.getElementById("selectedCity").value='';
        }
    }
    
  return (
    <>
        <div className='filter'>
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="footerform" data-aos="fade-up" data-aos-offset="10" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
    
                  <div className="form">
                  <ul>
                      <li>
                        <input type="text" name="user_mobile" id="user_mobile" placeholder='Mobile number*' pattern="[a-zA-Z\s]+" value={user_mobile} onChange={(e) => { setusermobile(e.target.value); }} onKeyUp={handledisable} ref={ref_mobile} />
                      </li>
                        <li>
                        <select name="selectedPlan" id="selectedPlan" value={selectedPlan} onChange={event => { setSelectedPlan(event.target.value); }} >
                                                    <option value="">Select {(cat=='all') ? 'Category' : 'Plan'}</option>
                                                    {plans.map(plan => (
                                                        <option key={plan.sno} value={plan.sno} dangerouslySetInnerHTML={createMarkup(plan.pname + (plan.flag ? ' (' + plan.flag +')' : ' (' + plan.ptitle + ')'))}></option>
                                                    ))}
                                                </select>
                                                </li>
                        <li><select name="selectedState" id="selectedState"  value={selectedState} onChange={event => { setSelectedState(event.target.value); getCities(event.target.value);}} required>
                                                    <option value="">Select Region</option>
                                                    {states.map(state => (
                                                        <option key={state.stateid} value={state.stateid}>{state.sname}</option>
                                                    ))}
                                                </select></li>
                        <li><select name="selectedCity" id="selectedCity"  value={selectedCity} onChange={event => setSelectedCity(event.target.value)} required>
                                                    <option value="">Select City</option>
                                                    {cities.map(city => (
                                                    <option key={city.cityid} value={city.cityid}>{city.cityname}</option>
                                                    ))}
                                                    </select></li>
                        <li className='btn-11 fill-color '><input type="submit" value="" id="submit_button" disabled={isDisabled} onClick={handleSubmit} /><span id="submit_button_text" className='disabled'>SUBMIT</span></li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
