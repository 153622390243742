import React, { useState, useEffect } from 'react'
import Header from './components/Header'
import { Contactform } from './components/Contactform'
import { Footer } from './components/Footer'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Form } from './components/Form';
import { Effectarrow } from './components/Effectarrow';
import axios from 'axios';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Hdbox = ({ match }) => {
  const slug = match.params.slug;
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/get_plans_data.php?catname=${slug}`)
      if (mounted) {
        setPagedata(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log('no data');
    // return null;
  }
  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    arrows: false,
    cssEase: "linear"
  };
  const [isActive, setIsActive] = useState(false);
  const [packageName, setpackageName] = useState('');
  const [packageChannel, setpackageChannel] = useState('');
  const [packageflag, setpackageflag] = useState('');
  const [packagePrice, setpackagePrice] = useState('');
  const [packageLink, setpackageLink] = useState('');
  const [pagedata2, setpagedata2] = useState('');

  const handleClick = (pname, pchannel, pbouquet, pprice, ppricetype, pid) => {
    setpackageName(pname);
    const featureS = pchannel.split("|_");
    setpackageLink(pid);
    setpackageChannel((pchannel > 0) ? pchannel : pbouquet);
    setpackageflag((pchannel > 0) ? "Channels" : "Bouquets");
    setpackagePrice("<i className='finr'></i>" + pprice + "/-<small>" + ppricetype + " <sup>*</sup></small>");
    axios.get(window.weburl + `api/get_channel_data2.php?pid=${pid}`)
      .then(response => setpagedata2(response.data))
      .catch(error => console.log(error));
    setIsActive(!isActive);
  };
  return (
    <>
      <Header />
      <section id='banner' className='p-0 internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <div className='banner'>
          <div className='leftarrow white'>
            <Effectarrow />
          </div>
          <div className='rightarrow white'>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='home-slider'>
                  <Slider {...settings}>
                    <div>
                      <div className='sliderrow align-items-center'>
                        <div className='slideimg'>
                          <img src={pagedata.cicon} alt='Banner' />
                        </div>
                        <div className='slidecont'>
                          <div className='circle01'>
                            <div className='mngcircle'>
                              <div className='circlimg'>
                                <h2 dangerouslySetInnerHTML={createMarkup(pagedata.remarks)}></h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section id='hdplan'>
        <div className='hdplan'>
          <div className='leftarrow'>
            <Effectarrow />
          </div>
          <div className='rightarrow'>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>

                {pagedata.data ?
                  <>
                    {pagedata.data.map(planData => {
                      const newValues = planData.features.split('|_');
                      return <>
                        {planData.tag == 'full' ?
                          <div className='hdsetupbox'>
                            <div className='setupboxtitle'>
                              <img src={planData.picon} />
                            </div>
                            <div className='setboxcont bundlebox'>
                              <div className='setboxdetl'>
                                <h4 dangerouslySetInnerHTML={createMarkup(planData.pname)}></h4>
                                <ul>
                                  {planData.features ?
                                    <>
                                      {newValues.map(featuresValue => {
                                        return <>
                                          {featuresValue ?
                                            <li dangerouslySetInnerHTML={createMarkup(featuresValue)}></li>
                                            : null}
                                        </>
                                      })}
                                    </>
                                    : null}
                                </ul>
                              </div>
                              <div className='setboxbuy'>
                                <div className='price'>
                                  <h5>{planData.aprice}</h5>
                                  <h6>{planData.price}</h6>
                                </div>
                                <div className="wrap"><Link className="btn-11 fill-color" to={'/book-now/' + planData.plan_id}><span>Get Now</span></Link></div>
                              </div>
                            </div>
                          </div>
                          : null}
                      </>
                    })}
                  </>
                  : null}
                <div className='title'><h3 dangerouslySetInnerHTML={createMarkup(pagedata.category)}></h3>
                </div>
                {pagedata.data ?
                  <div className='planstabpanel'>
                    <div className='channel'>
                      {pagedata.data.map(planData => {
                        const newValues = planData.features.split('|_');
                        return <>
                          {planData.tag != 'full' ?
                            <div className='channelpack' onClick={() => handleClick(planData.pname, planData.ccount, planData.bcount, planData.price, planData.ptype, planData.plan_id)}>
                              <h4 dangerouslySetInnerHTML={createMarkup(planData.pname)}></h4>
                              <ul>
                                {(planData.ccount > 0) ?
                                  (
                                    <li><strong dangerouslySetInnerHTML={createMarkup(planData.ccount)}></strong>Channels</li>
                                  )
                                  : (planData.bcount > 0) ?
                                    <li><strong dangerouslySetInnerHTML={createMarkup(planData.bcount)}></strong>Bouquets</li>
                                    : null}
                                <li><strong><i className='finr'></i>{planData.price}/-<small>{planData.ptype} <sup>*</sup></small></strong></li>
                              </ul>
                            </div>
                            : null}
                        </>
                      })}

                    </div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
        {/* Channel Pack Popup */}
        {/* <chennalpopup params = {}> */}
        <div className={isActive ? 'chanpackpop active' : 'chanpackpop'} >
          <div className='channelrow'>
            <span className='closepop' onClick={() => setIsActive(!isActive)}>X</span>
            <div className='channelhead'>
              <h4>{packageName}</h4>
              {packageChannel ?
                <h5><strong>{packageChannel}</strong> {packageflag}</h5>
                : null}
              <h6><i className='finr'></i><span dangerouslySetInnerHTML={createMarkup(packagePrice)}></span></h6>
            </div>
            {pagedata2 ?
              <div className='channelstn'>
                {Object.keys(pagedata2.data).map(value => (
                  <div className='channelbox'>
                    <h3>{pagedata2.data[value].catname}</h3>
                    {pagedata2.data[value].data2 ?
                      <ul>
                        {Object.keys(pagedata2.data[value].data2).map(value2 => (
                          <li><span><img src={pagedata2.data[value].data2[value2].cicon} /></span>{pagedata2.data[value].data2[value2].title}</li>
                        ))}
                      </ul>
                      : null}
                  </div>
                ))}
              </div>
              : null}
            <div className='booknow'>
              <div className="wrap"><Link className="btn-11 fill-color" to={'/book-now/' + packageLink}><span>Get Now</span></Link></div>
            </div>
          </div>
        </div>
        {/* Channel Pack Popup */}
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  )
}
