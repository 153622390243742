import React from 'react'

export const Bauquetsvg = (props) => {
  return (
    <>
          <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 257.97 162.57"
    {...props}
  >
    <path d="M254.42 65.03a3.55 3.55 0 0 0 3.55-3.55v-37.9a3.55 3.55 0 0 0-3.55-3.55h-34.43C218.27 8.71 208.5 0 196.71 0s-21.58 8.71-23.3 20.03h-68.47a3.55 3.55 0 0 0-3.55 3.55c0 9.08-7.39 16.48-16.48 16.48s-16.48-7.39-16.48-16.48a3.55 3.55 0 0 0-3.55-3.55h-41.3a3.55 3.55 0 0 0-3.55 3.55v34.64C8.71 59.94 0 69.72 0 81.52s8.71 21.56 20.03 23.28v34.19a3.55 3.55 0 0 0 3.55 3.55h41.31a3.55 3.55 0 0 0 3.55-3.55c0-9.08 7.39-16.48 16.48-16.48s16.48 7.39 16.48 16.48a3.55 3.55 0 0 0 3.55 3.55h68.47c1.72 11.32 11.5 20.03 23.3 20.03s21.56-8.71 23.28-20.03h34.43a3.55 3.55 0 0 0 3.55-3.55v-37.45a3.55 3.55 0 0 0-3.55-3.55c-9.1 0-16.5-7.39-16.5-16.48s7.4-16.5 16.5-16.5Zm-169.51 50.4c-11.79 0-21.56 8.71-23.28 20.03h-34.5v-33.9a3.55 3.55 0 0 0-3.55-3.55c-9.08 0-16.48-7.39-16.48-16.48s7.39-16.5 16.48-16.5a3.55 3.55 0 0 0 3.55-3.55V27.13h34.5c1.72 11.32 11.49 20.03 23.28 20.03s21.56-8.71 23.28-20.03h27.26v34.35a3.55 3.55 0 0 0 3.55 3.55c9.08 0 16.48 7.4 16.48 16.5S148.09 98.01 139 98.01a3.55 3.55 0 0 0-3.55 3.55v33.9h-27.26c-1.72-11.32-11.49-20.03-23.28-20.03Zm165.96-10.62v30.64h-34.13a3.55 3.55 0 0 0-3.55 3.55c0 9.08-7.39 16.48-16.48 16.48s-16.5-7.39-16.5-16.48a3.55 3.55 0 0 0-3.55-3.55h-34.11v-30.64c11.32-1.72 20.03-11.49 20.03-23.28s-8.71-21.58-20.03-23.3V27.14h34.11a3.55 3.55 0 0 0 3.55-3.55c0-9.08 7.4-16.48 16.5-16.48s16.48 7.39 16.48 16.48a3.55 3.55 0 0 0 3.55 3.55h34.13v31.09c-11.33 1.72-20.05 11.5-20.05 23.3s8.72 21.56 20.05 23.28Z" />
  </svg>
    </>
  )
}
