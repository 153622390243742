import React from 'react'
import Icon01 from '../images/sd-icon.webp'
import Icon02 from '../images/top-box.webp'
import Icon03 from '../images/entertainment.webp'
import Icon04 from '../images/hd-icon.webp'
import Icon05 from '../images/pay.webp'

export const Whyfastway = () => {
  return (
    <div className='web-container'>
    <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <h3>Why Fastway Digital Set Top Box?</h3> 
        </div>
    <div className='circleicons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
      
        <ul>
          <li><span><img src={Icon01} alt=''/></span><p>400+ SD & HD Channels</p></li>
          <li><span><img src={Icon02} alt=''/></span><p>Robust Set Top Box</p></li>
          <li><span><img src={Icon03} alt=''/></span><p>Unlimited Entertainment</p></li>
          <li><span><img src={Icon04} alt=''/></span><p>Unmatched Picture Quality</p></li>
          <li><span><img src={Icon05} alt=''/></span><p>Hassle Free Online Pay</p></li>
        </ul>
    </div>
</div>
  )
}
