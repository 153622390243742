import React, { useState } from 'react';
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Icon01 from './images/Home01.webp'
import Icon02 from './images/Home02.webp'
import Icon03 from './images/Home03.webp'
import Icon04 from './images/Home04.webp'
import Slider from 'react-slick';
import img02 from './images/about-fastway.webp';
import { Form } from './components/Form';
import { Numbers } from './components/Numbers';
import { Effectarrow } from './components/Effectarrow';

export const Aboutus = () => {
  const [state, setState] = useState({ turnover: 1, workforce: 1, states: 1, cities: 1 });
  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    arrows: false,
    cssEase: "linear"
  };
  return (
    <>
      <Header />
      <section id='banner' className='p-0 internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <div className='banner'>
          <div className='leftarrow white'>
            <Effectarrow />
          </div>
          <div className='rightarrow white'>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='home-slider'>
                  <Slider {...settings}>
                    <div>
                      <div className='sliderrow align-items-center'>
                        <div className='slideimg'>
                          <img src={img02} alt='Banner Netplus' />
                        </div>
                        <div className='slidecont'>
                          <div className='circle01'>
                            <div className='mngcircle'>
                              <div className='circlimg'>
                                <h2>Digital Entertainment<br /> in HD</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='homeautomation' className='pb-0'>
        <div className='aboutnetplus'>
          <div className='leftarrow '>
            <Effectarrow />
          </div>
          <div className='rightarrow '>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <span>FASTWAY TRANSMISSIONS</span>
                </div>
                <div className='smartcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <p>Fastway Transmissions is a pioneer in digital entertainment services and is the largest multiple-system operator (MSO) in North India.</p><p>Having commenced operations in 2007, the company has established strong infrastructure by laying down an extensive network of Optic Fiber Cable across Punjab, as well as other major regions in North India such as Haryana, Jammu & Kashmir, Rajasthan, Himachal Pradesh, Uttarakhand, Delhi NCR and Uttar Pradesh.</p><p>Fulfilling the entertainment appetite through 400 SD+HD channels, Fastway is a household name in North India. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='service'>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <span>SERVICES</span>
                </div>
                <div className='circleicons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <ul>
                    <li><span><img src={Icon01} alt='' /></span><p>Digital entertainment through 400 SD+HD channels</p></li>
                    <li><span><img src={Icon02} alt='' /></span><p>Fastway news and entertainment</p></li>
                    <li><span><img src={Icon03} alt='' /></span><p>Next-gen Android TV & OTT </p></li>
                    <li><span><img src={Icon04} alt='' /></span><p>Non-stop ad-free channels</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='number' className='pt-0'>
        <div className='number'>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='numberconts'>
                  <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <h3>Fastway in Numbers</h3>
                  </div>
                  <Numbers />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  )
}
