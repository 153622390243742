import React, {useState, useEffect} from 'react'
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import axios from 'axios';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
    return {__html: decodeHTML(content)};
}

export const Bst = () => {

  const [selectedchannels, setselectedchannels] = useState('');

  useEffect(() => {
    axios.get(window.weburl + 'api/get_channel_data.php')
      .then(response => setselectedchannels(response.data))
      .catch(error => console.log(error));
  }, []);

  return (
    <>
      <section id="blacktext" className="p-0">
      <Header />
      </section>
      <section id="contentpage">
        <div className="contentpage">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="heading">
                  <h4>Making A Better World</h4>
                </div>
                <div className="bst_cnt">
                  <h2>BST PACKAGE</h2>
                  <p>
                    Per STB invoice amount to Local Cable Operator effective 1st
                    Jan18 @ 29.29 PM*
                    </p>
                    {selectedchannels ?
                  <table class="table table-striped " id="Bst_table">
                    <tbody>
                      <tr>
                        <th>SR. NO. </th>
                        <th>CHANNEL NAME</th>
                        <th>CATEGORIES</th>
                        <th>AVAILABILITY</th>
                      </tr>
                      {selectedchannels.map ((cData, index) => (
                      <tr>
                        <td> {index + 1 }</td>
                        <td dangerouslySetInnerHTML={createMarkup(cData.title)}></td>
                        <td dangerouslySetInnerHTML={createMarkup(cData.cat_name)}></td>
                        <td> YES</td>
                      </tr>
                      ))}
                      <tr></tr>
                    </tbody>
                  </table>
                  : null}
                  <p class="headtext">
                    <strong>Notes:</strong>
                  </p>
                  <ol class="desclist">
                    <li>
                      Package price is per set top box per month. Billing to
                      start from the date of activation of set top box. GST
                      extra as applicable.
                    </li>
                    <li>Separate set top box required for each TV set.</li>
                    <li>
                      Packages/Channel Listings are subject to change from time
                      to time.
                    </li>
                    <li>
                      Minimum monthly subscription for any package subscribed in
                      combination with Add-On packages cannot be less than `150
                      (excluding taxes).
                    </li>
                    <li>
                      All terms & conditions specified in Package Authorization
                      Form (PAF) & Subscriber Hardware Form/ Customer Activation
                      Form (CAF) is applicable.
                    </li>
                    <li>
                      For Rented STB’s, Kindly go through “STB OPTION" annexure
                      on web site
                    </li>
                    <li>
                      For Subscriber Rates please contact your Local Cable
                      Operator*.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contactform />
      <Footer />
    </>
  );
};
