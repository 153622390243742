import React from 'react'

export const Hdsvg = (props) => {
  return (
    <>
          <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 200.26 143.64"
    {...props}
  >
    <path d="M27.47 136.54h145.32v7.1H27.47zM200.26 126.11H0V0h200.26v126.11ZM7.1 119h186.06V7.1H7.1V119Z" />
    <path d="M95.13 77.8h-4.9V59.19H72.1V77.8h-4.9V38.72h4.9V54.9h18.13V38.72h4.9V77.8ZM104.9 77.8V38.72h13.11c5.52 0 9.81 1.88 12.89 5.64 3.07 3.76 4.61 8.36 4.61 13.8s-1.5 10.17-4.5 13.96c-3 3.79-6.8 5.68-11.4 5.68h-14.72Zm4.9-4.15h7.93c4.2 0 7.39-1.45 9.56-4.35 2.18-2.9 3.26-6.73 3.26-11.48 0-3.85-1.04-7.32-3.11-10.39s-5.31-4.61-9.72-4.61h-7.93v30.84Z" />
  </svg>
    </>
  )
}
