import React from 'react'

export const Updatesvg = (props) => {
  return (
    <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 201.75 201.75"
    {...props}
  >
    <path d="M100.88 201.75C45.25 201.75 0 156.5 0 100.88S45.25 0 100.88 0s100.88 45.25 100.88 100.88-45.25 100.88-100.88 100.88Zm0-194.65C49.17 7.1 7.1 49.17 7.1 100.88s42.07 93.78 93.78 93.78 93.78-42.07 93.78-93.78S152.58 7.1 100.88 7.1Z" />
    <path d="m128.93 95.29-28.01-28.01-28.1 28.1 5.02 5.02 19.49-19.49v62.26h7.1V80.83l19.48 19.48 5.02-5.02z" />
  </svg>
    </>
  )
}
