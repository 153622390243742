import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import userIMG from "../images/AccountImg.webp";
import search_icon from "../images/search_btn.png";
import ALA from "../images/ALA-CARTE-CHANNEL.pdf";
import { Svg } from "./Svg";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

window.weburl = "https://www.fastway.in/";
export const Header = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);
  const canonical = window.location.href;
  console.log(canonical);
  const [menu, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!menu);
  };
  const [header, setNavbar] = useState(false);
  const ddd = window.location.href;
  console.log(ddd);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hoverClass = isHovered ? "hover" : "";

  const [urlvalue, seturlvalue] = useState("");
  useEffect(() => {
    axios
      .get(window.weburl + "api/get_urls.php")
      .then((response) => seturlvalue(response.data))
      .catch((error) => console.log(error));
  }, []);

  const handleSearch = () => {
    history.push(`/search?keyword=${encodeURIComponent(searchTerm)}`);
    // if (searchTerm.trim() !== '') {
    //   // Redirect to /search route with the keyword as a query parameter
    // }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <header
      id={props.Header_id ? props.Header_id : "header"}
      className={`${hoverClass}`}
    >
      <div className={header ? "header fixed" : "header"}>
        <div className="container">
          <div className="row">
            <div className="headerstn">
              <div className={menu ? "logo" : "logo active"}>
                <NavLink to="/">
                  <Svg />
                </NavLink>
              </div>
              <div className={!menu ? "menu active" : "menu"}>
                <div className="navbar">
                  <ul>
                    <li>
                      <NavLink to="/fastway-exclusive">
                        Fastway Exclusive
                      </NavLink>
                    </li>
                    <li
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <a
                        href="/digital-tv/fastway-sd-plus-packs"
                        className="desktop-show"
                      >
                        Digital TV
                      </a>
                      <a className="mobile-show">Digital TV</a>
                      <div className="dropdown">
                        <div className="submenu">
                          <div className="container">
                            {urlvalue ? (
                              <div className="menubox">
                                {urlvalue.map((urlVal) => (
                                  <div className="sitemapcol">
                                    <h4>
                                      <a href={"/digital-tv/" + urlVal.slug}>
                                        {urlVal.flag}
                                      </a>
                                    </h4>
                                  </div>
                                ))}
                                <div className="sitemapcol">
                                  <h4>
                                    <a href={ALA} target="_blank">
                                      Ala-Carte
                                    </a>
                                  </h4>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/channel-packs">Channel Packs</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ip-tv">FW+ IPTV</NavLink>
                    </li>
                    <li>
                      <NavLink to="/fastway-hd-bundle"> HD Bundle</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/live-tv">LIVE TV</NavLink>
                    </li> */}
                    <li>
                      <a
                        href="https://fwprepaid.myfastway.in/pay/index.php"
                        target="_blank"
                      >
                        Pay Online
                      </a>
                    </li>

                    <li
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <a>
                        <img className="accImg" src={userIMG} alt="Userimg" />
                        SMS Login
                      </a>
                      <div className="dropdown">
                        <div className="submenu">
                          <div className="container">
                            <div className="menubox">
                              {/* <div className='sitemapcol'>
                                <h4><a href='http://bssnew.myfastway.in:9003/oapservice' target='_blank'>Fastway BSS</a></h4>
                              </div> */}
                              <div className="sitemapcol">
                                <h4>
                                  <a
                                    href="http://lcoportal.myfastway.in/"
                                    target="_blank"
                                  >
                                    Report
                                  </a>
                                </h4>
                              </div>
                              <div className="sitemapcol">
                                <h4>
                                  <a
                                    href="http://smsiptv.myfastway.in:7765/login"
                                    target="_blank"
                                  >
                                   IPTV SMS
                                  </a>
                                </h4>
                              </div>
                              <div className="sitemapcol">
                                <h4>
                                  <a
                                    href="http://175.176.186.5:2019/login"
                                    target="_blank"
                                  >
                                    FW Prepaid
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* <li className="Srch_btn">
                      <form onSubmit={handleSearch}>

                        <input
                          type="text"
                          name=""
                          id=""
                          onChange={handleChange}
                          placeholder="Search for Movies, TV Shows, Channels & Actorss"
                        />
                        <button type="submit" >
                          <img src={search_icon} alt="" />
                        </button >
                      </form>
                    </li> */}
                    <li className="mobile-show">
                      <NavLink to="/about-fastway">About Fastway</NavLink>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/our-management">Our Management</NavLink>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/our-technology">Our Technology</NavLink>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/about-jujhar">Jujhar Group</NavLink>
                    </li>
                    <li className="mobile-show">
                      <a
                        href="https://www.jujhargroup.com/career?c=Fastway Transmissions Pvt. Ltd."
                        target="_blank"
                      >
                        Career
                      </a>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/download">Downloads</NavLink>
                    </li>
                    <li className="mobile-show">
                      <NavLink to="/faqs">Consumer Corner</NavLink>
                    </li>
                    <li className="mobile-show"><NavLink to="/contact-us"> Contact Us</NavLink></li>
                  </ul>
                </div>
                <div className="hemburgermenus">
                  <div className="dropdown">
                    <div className="submenu">
                      <div className="sitemapcol">
                        <ul>
                          <li>
                            <NavLink to="/about-fastway">About Fastway</NavLink>
                          </li>
                          <li>
                            <NavLink to="/our-management">
                              Our Management
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/our-technology">
                              Our Technology
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/about-jujhar">Jujhar Group</NavLink>
                          </li>
                          <li>
                            <a
                              href="https://www.jujhargroup.com/career?c=Fastway Transmissions Pvt. Ltd."
                              target="_blank"
                            >
                              Career
                            </a>
                          </li>
                          <li>
                            <a href="/fastway-event">Media</a>
                          </li>
                          <li>
                            <NavLink to="/download">Downloads</NavLink>
                          </li>
                          <li>
                            <NavLink to="/download">LCO CORNER</NavLink>
                          </li>
                          <li>
                            <NavLink to="/faqs">Consumer Corner</NavLink>
                          </li>
                          <li><NavLink to="/contact-us"> Contact Us</NavLink></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Search_nav_btn mobile-show">
                  <NavLink to="/search">
                    <img src={search_icon} alt="Search-icon" />
                  </NavLink>
                </div>
                <button
                  onClick={handleToggle}
                  className={menu ? "hemburgur" : "hemburgur active"}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
