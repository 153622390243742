import React, { useState, useEffect } from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Slider from 'react-slick'
import img01 from './images/poster-03.webp';
import { Form } from './components/Form'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom'
import { Effectarrow } from './components/Effectarrow';
import axios from 'axios';
import { Downloadsvg } from "./components/Downloadsvg";

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Channelpacks = ({ match }) => {
  const slug = match.params.slug;
  const sluglink = (slug) ? "pid=" + match.params.slug : '';
  const [catNewID, setcatNewID] = useState('15');

  const handleTabClick = (tabName) => {
    this.form('16');
  };


  const [isActive, setIsActive] = useState(false);
  const [packageName, setpackageName] = useState('');
  const [packageChannel, setpackageChannel] = useState('');
  const [packagePrice, setpackagePrice] = useState('');
  const [packageLink, setpackageLink] = useState('');
  const [pagedata2, setpagedata2] = useState('');
  const [pagepdf, setpagepdf] = useState("");

  const handleClick = (pname, pchannel, pprice, ppricetype, pid, pdfVal) => {
    setpackageName(pname);
    const featureS = pchannel.split("|_");
    setpackageChannel(featureS);
    setpackageLink(pid);
    setpagepdf(pdfVal);
    setpackagePrice("<i className='finr'></i>" + pprice + "/-<small>" + ppricetype + " <sup>*</sup></small>");
    axios.get(window.weburl + `api/get_channel_data2.php?pid=${pid}`)
      .then(response => setpagedata2(response.data))
      .catch(error => console.log(error));

    setIsActive(!isActive);
  };
  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    arrows: false,
    cssEase: "linear"
  };

  const [sdbox, setsdbox] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/plans.php?catID=15&${sluglink}`)
      if (mounted) {
        setsdbox(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);


  const [hdbox, sethdbox] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/plans.php?catID=16&${sluglink}`)
      if (mounted) {
        sethdbox(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);


  return (
    <>
      <Header />
      <section id='banner' className='p-0 grey-color internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <div className='banner'>
          <div className='leftarrow white'>
            <Effectarrow />
          </div>
          <div className='rightarrow white'>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='home-slider'>
                  <Slider {...settings}>
                    <div>
                      <div className='sliderrow align-items-center'>
                        <div className='slideimg'>
                          <img src={img01} alt='Banner Netplus' />
                        </div>
                        <div className='slidecont'>
                          <div className='circle01'>
                            <div className='mngcircle'>
                              <div className='circlimg'>
                                <h2>Your Entertainment. <br />Your Way.</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='filter' className='grey-color p-40'>
        <Form cat='cp' />
      </section>
      <section id='plans'>
        <div className='plans innerpack' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
          <div className='leftarrow '>
            <Effectarrow />
          </div>
          <div className='rightarrow '>
            <Effectarrow />
          </div>
          <div className='container' id='channel_pack'>
            <div className='row'>
              <div className='web-container'>
                <div className='planstab'>
                  <div className='title'>
                    <h3>Choose your pack of  <br />Entertainment</h3>
                  </div>
                  <Tabs>
                    {!slug ?
                      <TabList>
                        {sdbox.data ?
                          <Tab>Fastway SD+ Box</Tab>
                          : null}
                        {hdbox.data ?
                          <Tab>Fastway HD+ Box</Tab>
                          : null}
                      </TabList>
                      : null}
                    {sdbox.data ?
                      <TabPanel>
                        <div className='planstabpanel'>
                          <div className='channel'>
                            {sdbox.data.map(sdboxData => {
                              const newValues = sdboxData.features.split('|_');
                              return <div className='channelpack' onClick={() => handleClick(sdboxData.pname, sdboxData.ccount, sdboxData.price, sdboxData.ptype, sdboxData.plan_id, sdboxData.pdf)}>
                                <h4 dangerouslySetInnerHTML={createMarkup(sdboxData.pname)}></h4><ul>
                                  {sdboxData.ccount > 0 ? (
                                    <li>
                                      <strong
                                        dangerouslySetInnerHTML={createMarkup(
                                          sdboxData.ccount
                                        )}
                                      ></strong>
                                      Channels
                                    </li>
                                  ) : null}
                                  <li><strong><i className='finr'></i>{sdboxData.price}/-<small>{sdboxData.ptype} <sup>*</sup></small></strong></li>
                                </ul>
                              </div>
                            })}
                          </div>
                        </div>
                      </TabPanel>
                      : null}
                    {hdbox.data ?
                      <TabPanel>
                        <div className='planstabpanel'>
                          <div className='channel'>
                            {hdbox.data.map(hdboxData => {
                              const newValues = hdboxData.features.split('|_');
                              return <div className='channelpack' onClick={() => handleClick(hdboxData.pname, hdboxData.ccount, hdboxData.price, hdboxData.ptype, hdboxData.plan_id, hdboxData.pdf)}>
                                <h4 dangerouslySetInnerHTML={createMarkup(hdboxData.pname)}></h4>
                                <ul>
                                  {hdboxData.ccount > 0 ? (
                                    <li>
                                      <strong
                                        dangerouslySetInnerHTML={createMarkup(
                                          hdboxData.ccount
                                        )}
                                      ></strong>
                                      Channels
                                    </li>
                                  ) : null}
                                  <li><strong><i className='finr'></i>{hdboxData.price}/-<small>{hdboxData.ptype} <sup>*</sup></small></strong></li>
                                </ul>
                              </div>
                            })}
                          </div>
                        </div>
                      </TabPanel>
                      : null}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Channel Pack Popup */}
        <div className={isActive ? 'chanpackpop active' : 'chanpackpop'} >
          <div className='channelrow'>
            <span className='closepop' onClick={() => setIsActive(!isActive)}>X</span>
            <div className='channelhead'>
              <h4>{packageName}</h4>
              {packageChannel ?
                <h5><strong>{packageChannel.map(chData => (
                  chData
                ))}</strong> Channels</h5>
                : null}
              <h6><i className='finr'></i><span dangerouslySetInnerHTML={createMarkup(packagePrice)}></span></h6>
              {pagepdf ?
                <div className="dwm_pdf">
                  <a href={pagepdf} target="_blank" className="btn-11">
                    <span>
                      Download PDF <Downloadsvg />
                    </span>
                  </a>
                </div>
                : null}
            </div>
            {pagedata2 ?
              <div className='channelstn'>
                {Object.keys(pagedata2.data).map(value => (
                  <div className='channelbox'>
                    <div className="channel_title">
                      <p>{pagedata2.data[value].catname}</p>
                      {/* <p>1</p> */}
                      {pagedata2.data[value].price ? (
                        <p>{pagedata2.data[value].price}</p>
                      ) : null}
                    </div>
                    {/* <h3>{pagedata2.data[value].catname}</h3> */}
                    {pagedata2.data[value].data2 ?
                      <ul>
                        {Object.keys(pagedata2.data[value].data2).map(value2 => (
                          <li><span><img src={pagedata2.data[value].data2[value2].cicon} /></span>{pagedata2.data[value].data2[value2].title}</li>
                        ))}
                      </ul>
                      : null}
                  </div>
                ))}
              </div>
              : null}
            <div className='booknow'>
              <div className="wrap"><Link className="btn-11 fill-color" to={'/book-now/' + packageLink}><span>Get Now</span></Link></div>
            </div>
          </div>
        </div>
        {/* Channel Pack Popup */}
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  )
}
