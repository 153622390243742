import React from "react";
import { NavLink } from "react-router-dom";

export const Medianav = () => {
  return (
    <>
      <div className="medleft">
        <ul>
          <li>
            <NavLink to="/fastway-event">Events</NavLink>
          </li>
          <li>
            <a href="https://jujhargroup.com/newsroom/tag/fastway/">News</a>
          </li>
        </ul>
      </div>
    </>
  );
};
