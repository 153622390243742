import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";

export const Contactform = () => {
  // Insert Query Start
  const [user_name, setusername] = useState();
  const [user_mobile, setusermobile] = useState();
  const [user_email, setuseremail] = useState();
  // const [user_city, setusercity] = useState();
  // const [user_pincode, setuserpincode] = useState();

  // Focus
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_email = useRef(null);
  // const ref_city = useRef(null);
  // const ref_pincode = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const handledisable = () => {
    if (user_name && isValidPhone(user_mobile) && user_mobile) {
      const element = document.getElementById("submit_button_text");
      element.classList.remove("disabled");
      setIsDisabled(false);
    } else {
      const element = document.getElementById("submit_button_text");
      element.classList.add("disabled");
      setIsDisabled(true);
    }
  };

  function isValidPhone(phone) {
    const regex = /^[6-9]\d{9}$/gi;
    return regex.test(phone);
  }

  const handleSubmit = () => {
    if (!user_name) {
      alert("Please enter name");
      ref_name.current.focus();
      return false;
    } else if (!isValidPhone(user_mobile)) {
      alert("Please enter Mobile number");
      ref_mobile.current.focus();
      return false;
    } else {
      const url = window.weburl + "api/insert_hook.php";
      let fData = new FormData();
      fData.append("user_name", user_name);
      fData.append("user_phone", user_mobile);
      fData.append("user_email", user_email);
      // fData.append("user_city", user_city);
      // fData.append("user_pincode", user_pincode);
      fData.append("td_name", "gnc");
      fData.append("utm_url", window.location.href);
      axios.post(url, fData)
          .then(response => alert(response.data))
          .catch(error => alert(error));
          document.getElementById("submit_button_text").innerText='SUBMIT';
          document.getElementById("user_name").value='';
          document.getElementById("user_mobile2").value='';
          document.getElementById("user_email").value='';
    }
  };

  return (
    <>
      <section id="contactus" className="pb-0">
        <div className="container">
          <div className="enquireform2">
            <div className="row">
              <div className="web-container">
                <div
                  className="footerform"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <div className="form">
                    <h2>Get Your Connection</h2>
                    <ul>
                        <li>
                          <input
                            type="text"
                            name="user_name"
                            id="user_name"
                            placeholder="Name*"
                            required
                            value={user_name}
                            onChange={(e) => {
                              setusername(e.target.value);
                            }}
                            onKeyUp={handledisable}
                            ref={ref_name}
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            name="user_mobile"
                            id="user_mobile2"
                            placeholder="Phone*"
                            required
                            value={user_mobile}
                            onChange={(e) => {
                              setusermobile(e.target.value);
                            }}
                            onKeyUp={handledisable}
                            ref={ref_mobile}
                          />
                        </li>
                        <li>
                          <input
                            type="email"
                            name="user_email"
                            id="user_email"
                            placeholder="Email"
                            value={user_email}
                            onChange={(e) => {
                              setuseremail(e.target.value);
                            }}
                            ref={ref_email}
                          />
                        </li>
                        {/* <li>
                          <input
                            type="text" 
                            name="user_city"
                            id="user_city"
                            placeholder="City"
                            value={user_city}
                            onChange={(e) => {
                              setusercity(e.target.value);
                            }}
                            ref={ref_city}
                          />
                        </li> */}
                        {/* <li>
                          <input  
                            type="text"
                            name="user_pincode"
                            id="user_pincode"
                            placeholder="Pincode"
                            value={user_pincode}
                            onChange={(e) => {
                              setuserpincode(e.target.value);
                            }}
                            ref={ref_pincode}
                          />
                        </li> */}
                        <li className="btn-11 fill-color">
                          <input
                            type="submit"
                            value=""
                            id="submit_button"
                            disabled={isDisabled}
                            onClick={handleSubmit}
                          />
                          <span id="submit_button_text" className="disabled">
                            SUBMIT
                          </span>
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
