import React from 'react'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Header from './components/Header'
import Slider from 'react-slick'
import img01 from './images/digital-tv.webp';
import { Effectarrow } from './components/Effectarrow';
import { Form } from "./components/Form";
import PDF1 from './images/pak_alacarte_ch_form.pdf'
import PDF2 from './images/form.pdf'
import PDF3 from './images/lco_interconnect_agreement.pdf'
import PDF4 from './images/activation-deactivation.pdf'
import PDF5 from './images/MIA.pdf'
import PDF6 from './images/SIA.pdf'
import PDF7 from './images/SAF.pdf'
import { Downloadsvg } from './components/Downloadsvg'

export const Download = () => {
    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };
  return (
    <>
        <section id="blacktext" className="p-0">
        <Header />
      </section>
      
            <section id='download'>
                <div className='download'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                                        <div className='title'>
                                            <h3>Downloads</h3>
                                        </div>
                                        <div className='downloadstn'>
                                            <div className='downloadbox'>
                                            <a href={PDF1} target="_blank">  <h3>PACKAGE/A-LA-CARTE REQUEST FORM <br/><Downloadsvg/> </h3></a>                                                  
                                            </div>
                                            <div className='downloadbox'>
                                            <a href={PDF2} target="_blank"> <h3>FEED BACK FORM <br/><Downloadsvg/> </h3></a>                                               
                                            </div>
                                            <div className='downloadbox'>
                                            <a href={PDF3} target="_blank">  <h3>LCO INTERCONNECT AGREEMENT<br/><Downloadsvg/> </h3></a>                                                  
                                            </div>
                                            <div className='downloadbox'>
                                            <a href={PDF4} target="_blank">  <h3>ACTIVATION DEACTIVATION FORM<br/><Downloadsvg/> </h3></a>                                                  
                                            </div>
                                            <div className='downloadbox'>
                                            <a href={PDF5} target="_blank">  <h3>MODEL INTERCONNECTION AGREEMENT<br/><Downloadsvg/> </h3></a>                                                  
                                            </div>
                                            <div className='downloadbox'>
                                            <a href={PDF6} target="_blank">  <h3>STANDRAD INTERCONNECTION AGREEMENT<br/><Downloadsvg/> </h3></a>                                                  
                                            </div>
                                            <div className='downloadbox'>
                                            <a href={PDF7} target="_blank">  <h3>SUBSCRIBER AGREEMENT FORM<br/><Downloadsvg/> </h3></a>                                                  
                                            </div>
                                        </div>
                                    </div>
                            </div>
                    </div>
                </div>
            </section>
        {/* <Contactform /> */}
        <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
        <Footer />
    </>
  )
}
