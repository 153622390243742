import React from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";

export const Refund = () => {
  return (
    <>
      <section id="blacktext" className="p-0">
        <Header />
      </section>
      <section id="contentpage">
        <div className="contentpage">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="heading">
                  <h4>Terms & Conditions of Subscription charges offer</h4>
                </div>
                <p class="headtext">
                  The offer is available only through the following Online
                  Payment of your Cable TV Subscription For any further details,
                  visit our website www.fastway.in
                </p>
                <ul class="desclist">
                  <li>
                    The above offer is available only for Online Payment [ ].
                    For any further details, visit our website at www.fastway.in
                  </li>
                  <li>
                    Company will provide services during Subscription charges
                    scheme period on STB’s as per Company policy at authorized
                    service center
                  </li>
                  <li>VC remains the property of Company.</li>
                  <li>
                    No transfer and / or shifting allowed without prior
                    permission of Fastway during subscription charges scheme
                    period. The Company retains the right to discontinue the
                    schemeto any customer, who has availed the scheme but has
                    deliberately transferred / shifted without the permission of
                    Fastway.
                  </li>
                  <li>
                    Any transfer / re-location done by company will be on
                    chargeable basis as per company policy.
                  </li>
                  <li>Kindly visit website for the subscription Charges.</li>
                  <li>
                    Below is the Chart wherein a customer can get Refund In the
                    Case where He want to Withdraw the Services.
                  </li>
                  <li>
                    Customer cancelling after the 15 will not get any refund for
                    the current month.
                  </li>
                  <li>
                    THE above (c) and (d) in the case customer pays Advance
                    payments only.
                  </li>

                  <li>
                    For HDTV all Charges are same in all Packages i.e Rs 350 Per
                    Month.
                  </li>
                </ul>
                <table class="table table-striped" id="Refund_table">
                  <tbody>
                    <tr>
                      <th></th>
                      <th colspan="2">BASIC SERVICE</th>
                      <th colspan="2">SILVER</th>
                      <th colspan="2">GOLD</th>
                      <th colspan="2">PLATINUM</th>
                      <th colspan="2">HD</th>
                    </tr>
                    <tr>
                      <td></td>
                      <td>1St Tv </td>
                      <td>2Nd Tv or Additional Maximum 3 TVs</td>

                      <td>1St Tv </td>
                      <td>2Nd Tv or Additional Maximum 3 TVs</td>

                      <td>1St Tv </td>
                      <td>2Nd Tv or Additional Maximum 3 TVs</td>

                      <td>1St Tv </td>
                      <td>2Nd Tv or Additional Maximum 3 TVs</td>

                      <td>1St Tv </td>
                      <td>2Nd Tv or Additional Maximum 3 TVs</td>
                    </tr>

                    <tr>
                      <td>
                        (a) For the Current Month, Before of Every of 7th Day
                      </td>
                      <td>75</td>
                      <td>60</td>
                      <td>125</td>
                      <td>65</td>
                      <td>150</td>
                      <td>63</td>
                      <td>200</td>
                      <td>100</td>
                      <td>250</td>
                      <td>220</td>
                    </tr>

                    <tr>
                      <td>
                        (b) For the Current Month, Before of Every of 15th Day
                      </td>
                      <td>50</td>
                      <td>45</td>
                      <td>60</td>
                      <td>45</td>
                      <td>120</td>
                      <td>43</td>
                      <td>150</td>
                      <td>75</td>
                      <td>180</td>
                      <td>170</td>
                    </tr>
                    <tr>
                      <td>
                        (c) For the Next Month Before the 1 Day of the month
                      </td>
                      <td>90</td>
                      <td>90</td>
                      <td>130</td>
                      <td>90</td>
                      <td>180</td>
                      <td>89</td>
                      <td>225</td>
                      <td>125</td>
                      <td>325</td>
                      <td>275</td>
                    </tr>
                    <tr>
                      <td>(d) For the Coming Months</td>
                      <td>100</td>
                      <td>100</td>
                      <td>146</td>
                      <td>102</td>
                      <td>191</td>
                      <td>102.35</td>
                      <td>250</td>
                      <td>150</td>
                      <td>350</td>
                      <td>300</td>
                    </tr>
                  </tbody>
                </table>
                <p class="headtext">
                 <strong>Notes:</strong>
              </p>
                <ol class="desclist">
                  <li>
                    Package price is per set top box per month. Billing to start
                    from the date of activation of set top box. All applicable
                    taxes extra.
                  </li>
                  <li>Separate set top box required for each TV set.</li>
                  <li>
                    Packages/Channel Listings are subject to change from time to
                    time
                  </li>
                  <li>
                    Minimum subscription period is three (3) months.Minimum
                    monthly subscription for any package subscribed in
                    combination with Add-On packages or A-la-Carte channels
                    cannot be less than `100 (excluding taxes).
                  </li>
                  <li>
                    All terms & conditions specified in Package Authorization
                    Form (PAF) & Subscriber Hardware Form/Customer Activation
                    Form (CAF) are applicable.
                  </li>
                  <li>
                    For Rented STB’s, kindly go through “STB OPTION” annexure on
                    web site.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contactform />
      <Footer />
    </>
  );
};
