import React, { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import { Numbers } from "./components/Numbers";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import img01 from "./images/banner_01.webp";
import img01A from "./images/banner_02.webp";
import img01C from './images/banner_05.webp'
import img01B from "./images/banner_03.webp";
import img02 from "./images/poster-02.webp";
import img03 from "./images/poster-03.webp";
import Okgoogle from "./images/okgoogle.webp";
import setupbox from "./images/setupbox.webp";
import setupbox2 from './images/setupbox2.webp'
import details from './images/details.webp'
import Sd from "./images/sd.webp";
import Hd from "./images/hd.webp";
import Fastway from "./images/fastwaysetupbox.webp";
import { Link } from "react-router-dom";
import { Form } from "./components/Form";
import { Whyfastway } from "./components/Whyfastway";
import { Jujharcont } from "./components/Jujharcont";
import ALA from "./images/ALA-CARTE-CHANNEL.pdf";
import { Sdsvg } from "./components/Sdsvg";
import { Hdsvg } from "./components/Hdsvg";
import { Bauquetsvg } from "./components/Bauquetsvg";
import { Updatesvg } from "./components/Updatesvg";
import { Effectarrow } from "./components/Effectarrow";
import Hdbundle from "./images/Hd-bundle-banner.webp";
import no_img from "./images/no-image.webp";
import search_icon from "./images/search_btn.png";
import Bst_pdf from "./images/BST-NCF.pdf";
import axios from "axios";
import { Downloadsvg } from "./components/Downloadsvg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink } from "react-router-dom";

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Home = () => {
  const [sdbox, setsdbox] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        window.weburl + "api/plans.php?catID=15&limit=3"
      );
      if (mounted) {
        setsdbox(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  const [hdbox, sethdbox] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        window.weburl + "api/plans.php?catID=16&limit=3"
      );
      if (mounted) {
        sethdbox(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 6000,
    arrows: false,
    cssEase: "linear",
  };
  var settings1 = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 2000,
    arrows: false,
    cssEase: "linear",
  };
  const [isActive, setIsActive] = useState(false);
  const [packageName, setpackageName] = useState("");
  const [packageChannel, setpackageChannel] = useState("");
  const [packagePrice, setpackagePrice] = useState("");
  const [packageLink, setpackageLink] = useState("");
  const [pagedata, setpagedata] = useState("");
  const [pagepdf, setpagepdf] = useState("");

  const handleClick = (pname, pchannel, pprice, ppricetype, pid, pdfVal) => {
    setpackageName(pname);
    const featureS = pchannel.split("|_");
    setpackageChannel(featureS);
    setpackageLink(pid);
    setpagepdf(pdfVal);
    setpackagePrice(
      "<i className='finr'></i>" +
      pprice +
      "/-<small>" +
      ppricetype +
      " <sup>*</sup></small>"
    );
    axios
      .get(window.weburl + `api/get_channel_data2.php?pid=${pid}`)
      .then((response) => setpagedata(response.data))
      .catch((error) => console.log(error));

    setIsActive(!isActive);
  };
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = () => {
    history.push(`/search?keyword=${encodeURIComponent(searchTerm)}`);

  };
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <>
      <Header />
      <section
        id="banner"
        className="p-0 grey-color internal"
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="banner">
          <div className="leftarrow white">
            <Effectarrow />
          </div>
          <div className="rightarrow white">
            <Effectarrow />
          </div>
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="home-slider">
                  <Slider {...settings}>
                    <div>
                      <div className="sliderrow">
                        <div className="slideimg">
                          <Slider {...settings1}>

                            <div>
                              <div className="animatedimg">
                                <img src={img01C} alt="Banner Fastway" />
                              </div>
                            </div>

                            <div>
                              <div className="animatedimg">
                                <img src={img01} alt="Banner Fastway" />
                              </div>
                            </div>
                            <div>
                              <div className="animatedimg">
                                <img src={img01A} alt="Banner Fastway" />
                              </div>
                            </div>
                            <div>
                              <div className="animatedimg">
                                <img src={img01B} alt="Banner Fastway" />
                              </div>
                            </div>
                          </Slider>
                        </div>

                        <div className="slidecont">
                          <div className="circle01">
                            <div className="mngcircle">
                              <div className="circlimg">
                                <h2>
                                  Your gateway to Unmatched Entertainment!
                                </h2>
                              </div> <br /> <br />
                              <div className="prize">
                                {/* <div className="googleicon">
                                  <img src={Okgoogle} alt="" />
                                </div> */}
                                {/* <div className="pack shape pricestn">
                                  <div className="pprice">
                                    <ul>
                                      <li>
                                        <strong>3695 /-</strong>
                                      </li>
                                    </ul>
                                    <h5>
                                      now at{" "}
                                      <strong>
                                        <i className="finr"></i> 2500/-
                                      </strong>
                                    </h5>
                                  </div>
                                </div> */}
                                <div class="listpoint">
                                <img src={details} alt="" />
                                </div>
                              
                                <div className="booknow ">
                                  <div className="wrap">
                                    <Link
                                      className="btn-11"
                                      to="/book-now/Ynl3WVU4N1h3ZWtDTVpNZ29YbmQyQT09"
                                    >
                                      <span>Get New Connection</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="setupbox">
                                <img src={setupbox2} alt="" />
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                    <div>
                      <div className="sliderrow">
                        <div className="slideimg">
                          <img src={img02} alt="Banner Fastway" />
                        </div>
                        <div className="slidecont">
                          <div className="circle01">
                            <div className="mngcircle">
                              <div className="circlimg">
                                <h2>
                                  Pure drama. <br />
                                  HDR Streaming way.
                                </h2>
                              </div>
                              <div className="prize">
                                {/* <div className="googleicon">
                                  <img src={Okgoogle} alt="" />
                                </div> */}
                                <div className="pack shape pricestn">
                                  <div className="pprice">
                                    <ul>
                                      <li>
                                        <strong>1200 /-</strong>
                                      </li>
                                    </ul>
                                    <h5>
                                      now at{" "}
                                      <strong>
                                        <i className="finr"></i> 800/-
                                      </strong>
                                    </h5>
                                  </div>
                                </div>
                                <div className="booknow ">
                                  <div className="wrap">
                                    <Link
                                      className="btn-11"
                                      to="/book-now/Sm1VZEFTRHZYUEhqZ0dwNldTVGFlUT09"
                                    >
                                      <span>Get Now</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="prize">
                                <div className="setupbox">
                                  <img src={Fastway} alt="" />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="sliderrow">
                        <div className="slideimg">
                          <img src={img03} alt="Banner Fastway" />
                        </div>
                        <div className="slidecont">
                          <div className="circle01">
                            <div className="mngcircle">
                              <div className="circlimg">
                                <h2>
                                  Big value bundle <br /> starting{" "}
                                  <i className="finr"></i>699/month
                                </h2>
                                <h4>
                                  Live HD TV + Unlimited
                                  <br /> Data
                                </h4>
                              </div>
                              <div className="prize">
                                <div className="setupbox">
                                  <img src={setupbox} alt="" />
                                </div>
                              </div>
                              <div className="booknow justify-content-start">
                                <div className="wrap">
                                  <Link
                                    className="btn-11"
                                    to="/book-now/RFc1WDNibU9YaU1OZDZscko5TmNEZz09"
                                  >
                                    <span>Get Now</span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <div className='sliderrow'>
                        <div className='slideimg'>
                          <img src={Hdbundle} alt='Banner Fastway' />
                        </div>
                        <div className='slidecont'>
                          <div className='circle01'>
                            <div className='mngcircle'>
                              <div className='circlimg designbox'>
                                <h4>Pay For <strong>3 </strong> Months <span>Get <strong> Free</strong></span></h4>
                                <h4>Pay For <strong>5 </strong> Months <span>Get <strong>1 Month Free</strong></span></h4>
                                <h4>Pay For <strong>10 </strong> Months <span> Get <strong>2 Months Free</strong></span></h4>
                              </div>
                              <div className='prize'>
                                <div className='setupbox'>
                                  <img src={setupbox} alt='' />
                                </div>
                              </div>
                              <div className='booknow justify-content-start'>
                                <div className="wrap"><Link className="btn-11" to='/book-now/RFc1WDNibU9YaU1OZDZscko5TmNEZz09'><span>Get Now</span></Link></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="filter" className="grey-color">
        <Form cat="all" />
      </section>
      <section id="aboutfastway">
        <div className="aboutfastway">
          <div className="leftarrow ">
            <Effectarrow />
          </div>
          <div className="rightarrow ">
            <Effectarrow />
          </div>
          <div className="container">
            <div className="row">
              <div
                className="title"
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >

                <h3>Discover your Favorite<br></br> Celebrity's Movies Streaming Today on your TV Channels!
                  <li className="Srch_btn" >
                    <form onSubmit={handleSearch}>

                      <input
                        type="text"
                        placeholder="Search here!"
                        name=""
                        id=""
                        onChange={handleChange}

                      />
                      <button type="submit">
                        <img src={search_icon} alt="" />
                      </button >
                    </form>
                  </li>
                </h3>
                <div className="Search_nav_btn mobile-show">
                  <NavLink to="/search">
                    <img src={search_icon} alt="Search-icon" />
                  </NavLink>
                </div>

                <br></br><br></br><br></br><br></br>

                <h3>Entertainment. supercharged.</h3>
                <p>
                  Enjoy unlimited entertainment, news, movies, sports &
                  exclusive live coverage of holy places like Anandpur Sahib,
                  Gurudwara Deep Singh ji with amazing picture quality and
                  crystal-clear surround sound.{" "}
                </p>
              </div>
              <div
                className="verticaltabs"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <Tabs>
                  <TabList>
                    <Tab>
                      <Sdsvg />
                      <span>SD+</span>
                    </Tab>
                    <Tab>
                      <Hdsvg />
                      <span>HD+</span>
                    </Tab>
                    <Tab>
                      <Bauquetsvg /> <span>A-lacarte</span>
                    </Tab>
                    <Tab>
                      <Updatesvg />
                      <span>Upgrade</span>
                    </Tab>
                  </TabList>
                  <div className="tabpanel">
                    <TabPanel>
                      <div className="vertaltabbox">
                        <div className="vtabstn">
                          <div className="vtabimg">
                            <img src={Sd} alt="" />
                          </div>
                          <div className="vtabcont">
                            <ul>
                              <li>Access to 400+ channels</li>
                              <li>16:9 aspect ratio</li>
                              <li>High quality picture resolution</li>
                              <li>Free access to Fastway local channels</li>
                            </ul>
                          </div>
                          <div className="book">
                            <div className="wrap gredientbtn">
                              <Link
                                className="btn-11"
                                to="digital-tv/fastway-sd-plus-packs"
                              >
                                <span>Get Now</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="vertaltabbox">
                        <div className="vtabstn">
                          <div className="vtabimg">
                            <img src={Hd} alt="" />
                          </div>
                          <div className="vtabcont">
                            <ul>
                              <li>Over 400 Digital Channels</li>
                              <li>Over 65 Digital HD Channels</li>
                              <li>HEVC Technology</li>
                              <li>1080i Picture Resolution</li>
                            </ul>
                          </div>
                          <div className="book">
                            <div className="wrap gredientbtn">
                              <Link
                                className="btn-11"
                                to="digital-tv/fastway-hd-plus-packs"
                              >
                                <span>Get Now</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="vertaltabbox">
                        <div className="vtabstn">
                          <div className="vtabimg">
                            <Bauquetsvg />
                          </div>
                          <div className="vtabcont">
                            <ul>
                              <li>
                                Starts from{" "}
                                <strong>
                                  <i className="finr"></i> 19
                                </strong>
                                <p>
                                  Pick and pay to subscribe your channel packs
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="book">
                            <div className="wrap gredientbtn">
                              <a className="btn-11" href={ALA} target="_blank">
                                <span>Get Now</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="vertaltabbox">
                        <div className="vtabstn">
                          <div className="vtabimg">
                            <Updatesvg />
                          </div>
                          <div className="vtabcont">
                            <ul>
                              <li>
                                Starts from{" "}
                                <strong>
                                  {" "}
                                  <i className="finr"></i> 699
                                </strong>
                                <p>
                                  Upgrade your Set top box to Fastway latest
                                  Android Ultra 4K with HDR streaming
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="book">
                            <div className="wrap gredientbtn">
                              <Link
                                className="btn-11"
                                to="digital-tv/fastway-hd-plus-packs"
                              >
                                <span>Get Now</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="plans">
        <div
          className="plans"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="leftarrow ">
            <Effectarrow />
          </div>
          <div className="rightarrow ">
            <Effectarrow />
          </div>
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="planstab">
                  <div className="title">
                    <h3>Choose your pack of Entertainment</h3>
                  </div>
                  <Tabs>
                    <TabList>
                      {sdbox.data ? <Tab>Fastway SD+ Box</Tab> : null}
                      {sdbox.data ? <Tab>Fastway HD+ Box</Tab> : null}
                    </TabList>
                    {sdbox.data ? (
                      <TabPanel>
                        <div className="planstabpanel">
                          <div className="channel">
                            {sdbox.data.map((sdboxData) => {
                              const newValues = sdboxData.features.split("|_");
                              return (
                                <div
                                  className="channelpack"
                                  onClick={() =>
                                    handleClick(
                                      sdboxData.pname,
                                      // sdboxData.features,
                                      sdboxData.ccount,
                                      sdboxData.price,
                                      sdboxData.ptype,
                                      sdboxData.plan_id,
                                      sdboxData.pdf
                                    )
                                  }
                                >
                                  <h4
                                    dangerouslySetInnerHTML={createMarkup(
                                      sdboxData.pname
                                    )}
                                  ></h4>
                                  <ul>
                                    <li>
                                      <strong>
                                        {sdboxData.ccount}
                                      </strong>
                                      Channels
                                    </li>
                                    <li>
                                      <strong>
                                        <i className="finr"></i>
                                        {sdboxData.price}/-
                                        <small>
                                          {sdboxData.ptype} <sup>*</sup>
                                        </small>
                                      </strong>
                                    </li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                          <div className="booknow">
                            <div className="wrap">
                              <Link
                                className="btn-11"
                                to={"/digital-tv/" + sdbox.categoryslug}
                              >
                                <span>Explore Packs</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ) : null}
                    {hdbox.data ? (
                      <TabPanel>
                        <div className="planstabpanel">
                          <div className="channel">
                            {hdbox.data.map((hdboxData) => {
                              const newValues = hdboxData.features.split("|_");
                              return (
                                <div
                                  className="channelpack"
                                  onClick={() =>
                                    handleClick(
                                      hdboxData.pname,
                                      hdboxData.ccount,
                                      hdboxData.price,
                                      hdboxData.ptype,
                                      hdboxData.plan_id,
                                      hdboxData.pdf
                                    )
                                  }
                                >
                                  <h4
                                    dangerouslySetInnerHTML={createMarkup(
                                      hdboxData.pname
                                    )}
                                  ></h4>
                                  <ul>
                                    <li>
                                      <strong>
                                        {hdboxData.ccount}
                                      </strong>
                                      Channels
                                    </li>
                                    <li>
                                      <strong>
                                        <i className="finr"></i>
                                        {hdboxData.price}/-
                                        <small>
                                          {hdboxData.ptype} <sup>*</sup>
                                        </small>
                                      </strong>
                                    </li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                          <div className="booknow">
                            <div className="wrap">
                              <Link
                                className="btn-11"
                                to={"/digital-tv/" + hdbox.categoryslug}
                              >
                                <span>Explore Packs</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ) : null}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Channel Pack Popup */}
        <div className={isActive ? "chanpackpop active" : "chanpackpop"}>
          <div className="channelrow">
            <span className="closepop" onClick={() => setIsActive(!isActive)}>
              X
            </span>
            <div className="channelhead">
              <h4>{packageName}</h4>
              {packageChannel ? (
                <h5>
                  <strong>{packageChannel.map((chData) => chData)}</strong>{" "}
                  Channels
                </h5>
              ) : null}
              <h6>
                <i className="finr"></i>
                <span
                  dangerouslySetInnerHTML={createMarkup(packagePrice)}
                ></span>
              </h6>
              {pagepdf ?
                <div className="dwm_pdf">
                  <a href={pagepdf} target="_blank" className="btn-11">
                    <span>
                      Download PDF <Downloadsvg />
                    </span>
                  </a>
                </div>
                : null}
            </div>
            {pagedata ? (
              <div className="channelstn">
                {Object.keys(pagedata.data).map((value) => (
                  <div className="channelbox">
                    <div className="channel_title">
                      <p>{pagedata.data[value].catname}</p>
                      {/* <p>1</p> */}
                      {pagedata.data[value].price ? (
                        <p>{pagedata.data[value].price}</p>
                      ) : null}
                    </div>

                    {pagedata.data[value].data2 ? (
                      <ul>
                        {Object.keys(pagedata.data[value].data2).map(
                          (value2) => (
                            <li>
                              <span>
                                {pagedata.data[value].data2[value2].cicon ? (
                                  <img
                                    src={
                                      pagedata.data[value].data2[value2].cicon
                                    }
                                  />
                                ) : (
                                  <img src={no_img} alt="" />
                                )}
                              </span>
                              {pagedata.data[value].data2[value2].title}
                            </li>
                          )
                        )}
                      </ul>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
            <div className="booknow">
              <div className="wrap">
                <Link
                  className="btn-11 fill-color"
                  to={"/book-now/" + packageLink}
                >
                  <span>Get Now</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Channel Pack Popup */}
      </section>
      <section id="whyfastway" className="pb-0">
        <div className="retail">
          <div className="leftarrow ">
            <Effectarrow />
          </div>
          <div className="rightarrow ">
            <Effectarrow />
          </div>
          <div className="container">
            <div className="row">
              <Whyfastway />
            </div>
          </div>
        </div>
      </section>
      <section id="number" className="pt-0">
        <div className="number">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="numberconts">
                  <div
                    className="title"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                  >
                    <h3>Fastway in Numbers</h3>
                  </div>
                  <Numbers />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Jujharcont />
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  );
};
export default Home;
