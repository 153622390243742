import React, { useEffect, useState } from "react";

export const Card = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate the loading of the image
    const image = new Image();
    image.src = props.Image;
    image.onload = () => setIsLoading(false);
    image.onerror = () => setIsLoading(false);
  }, [props.Image]);

  return (
    <>
      <div className="search_data_card">
        <div className="search_card_cnt">
          <div className="search_card_img">
           {isLoading ? (
                 <div className="Image_loader">
                 </div>
            ) : (
              <img src={props.Image} alt="" />
            )}
          </div>
          <div className="search_card_data">
            <div className="search_card_data_lft">
              <h5>{props.Name}</h5>
             <>
             {props.ChannelNumber === null?
               null
                :
             ( <p>
               channel no. <strong>{props.ChannelNumber}</strong>
              </p>)
                  }
             </>
              <p>{props.BoradCastTime}</p>
            </div>
            <div className="search_card_data_rgt">
              <div className="serach_logo_img">
                <img src={props.logoImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
