import React, { useState } from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import { Form } from './components/Form'
import Slider from 'react-slick'
import img01 from './images/digital-tv.webp';
import { Link } from 'react-router-dom'
import { Effectarrow } from './components/Effectarrow';
import Logo01 from './images/channellogo01.webp'
import Logo02 from './images/channellogo02.webp'
import Logo03 from './images/channellogo03.webp'
import Logo04 from './images/channellogo04.webp'
import Logo05 from './images/channellogo05.webp'
import Logo06 from './images/channellogo06.webp'
import Logo07 from './images/channellogo07.webp'

export const Sdbox = () => {
    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };
    const [isActive, setIsActive] = useState(false);
  return (
    <>
        <Header />
        <section id='banner' className='p-0 internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner'>
                <div className='leftarrow white'>
              <Effectarrow />
          </div>          
          <div className='rightarrow white'>
          <Effectarrow />
          </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='home-slider'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='sliderrow align-items-center'>
                                                <div className='slideimg'>
                                                    <img src={img01} alt='Banner Netplus' />
                                                </div>
                                                <div className='slidecont'>
                                                    <div className='circle01'>
                                                        <div className='mngcircle'>
                                                            <div className='circlimg'>
                                                                <h2>Entertainment. supercharged.</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='sdplan'>
                <div className='sdplans'>
                <div className='leftarrow '>
              <Effectarrow />
          </div>          
          <div className='rightarrow '>
          <Effectarrow />
          </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='title'>
                                        <h3>Fastway SD Plus Packs</h3>
                                </div>
                                <div className='planstabpanel'>
                      <div className='channel'>
                      <div className='channelpack' onClick={() => setIsActive(!isActive)}>
                          <h4>Basic Service Tier</h4>
                          <ul>
                            <li><strong>127</strong>Channels</li>
                            <li><strong><i className='finr'></i>130/-<small>month <sup>*</sup></small></strong></li>
                          </ul>
                      </div>
                      <div className='channelpack' onClick={() => setIsActive(!isActive)}>
                          <h4>FW Favourite</h4>
                          <ul>
                            <li><strong>32</strong>Channels</li>
                            <li><strong><i className='finr'></i>199/-<small>month <sup>*</sup></small></strong></li>
                          </ul>
                      </div>
                      <div className='channelpack' onClick={() => setIsActive(!isActive)}>
                          <h4>FW Favourite+</h4>
                          <ul>
                            <li><strong>36</strong>Channels</li>
                            <li><strong><i className='finr'></i>245/-<small>month <sup>*</sup></small></strong></li>
                          </ul>
                      </div>         
                      <div className='channelpack' onClick={() => setIsActive(!isActive)}>
                          <h4>Silver</h4>
                          <ul>
                            <li><strong>87</strong>Channels</li>
                            <li><strong><i className='finr'></i>350/-<small>month <sup>*</sup></small></strong></li>
                          </ul>
                      </div>        
                      <div className='channelpack' onClick={() => setIsActive(!isActive)}>
                          <h4>Gold +</h4>
                          <ul>
                            <li><strong>102</strong>Channels</li>
                            <li><strong><i className='finr'></i>400/-<small>month <sup>*</sup></small></strong></li>
                          </ul>
                      </div>      
                      <div className='channelpack' onClick={() => setIsActive(!isActive)}>
                          <h4>Ptatinum</h4>
                          <ul>
                            <li><strong>113</strong>Channels</li>
                            <li><strong><i className='finr'></i>450/-<small>month <sup>*</sup></small></strong></li>
                          </ul>
                       
                      </div>       
                    </div>
                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                  {/* Channel Pack Popup */}
          <div className={isActive ? 'chanpackpop active' : 'chanpackpop'} >
                                  <div className='channelrow'>
                                        <span className='closepop' onClick={() => setIsActive(!isActive)}>X</span>
                                    <div className='channelhead'>
                                        <h4>Basic Service Tier</h4>
                                        <h5>Channels 127</h5>
                                        <h6><i className='finr'></i>130/-<small>month <sup>*</sup></small></h6>
                                    </div>
                                    <div className='channelstn'>
                                    <div className='channelbox'>
                                      <h3>GEC HINDI</h3>
                                      <ul>
                                        <li><span><img src={Logo01} /></span>DANGAL</li>
                                        <li><span><img src={Logo02} /></span>DANGAL 2</li>
                                        <li><span><img src={Logo03} /></span>SHEMAROO UMANG</li>
                                        <li><span><img src={Logo04} /></span>SHEMAROO TV</li>
                                        <li><span><img src={Logo05} /></span>DD NATIONAL</li>
                                        <li><span><img src={Logo06} /></span>E 24</li>
                                        <li><span><img src={Logo07} /></span>DD RETRO</li>
                                        <li><span><img src={Logo01} /></span>DD UP</li>
                                        <li><span><img src={Logo02} /></span>DD HIMACHAL PRADESH</li>
                                        <li><span><img src={Logo03} /></span>DD HISAR</li>
                                        <li><span><img src={Logo04} /></span>DD RAJASTHAN</li>
                                      </ul>
                                    </div>
                                    <div className='channelbox'>
                                      <h3>PUNJABI </h3>
                                      <ul>
                                        <li><span><img src={Logo01} /></span>DANGAL</li>
                                        <li><span><img src={Logo02} /></span>DANGAL 2</li>
                                        <li><span><img src={Logo03} /></span>SHEMAROO UMANG</li>
                                        <li><span><img src={Logo04} /></span>SHEMAROO TV</li>
                                        <li><span><img src={Logo05} /></span>DD NATIONAL</li>
                                        <li><span><img src={Logo06} /></span>E 24</li>
                                        <li><span><img src={Logo07} /></span>DD RETRO</li>
                                        <li><span><img src={Logo01} /></span>DD UP</li>
                                        <li><span><img src={Logo02} /></span>DD HIMACHAL PRADESH</li>
                                        <li><span><img src={Logo03} /></span>DD HISAR</li>
                                        <li><span><img src={Logo04} /></span>DD RAJASTHAN</li>
                                      </ul>
                                    </div>
                                    <div className='channelbox'>
                                      <h3>NEWS PUNJABI </h3>
                                      <ul>
                                        <li><span><img src={Logo01} /></span>DANGAL</li>
                                        <li><span><img src={Logo02} /></span>DANGAL 2</li>
                                        <li><span><img src={Logo03} /></span>SHEMAROO UMANG</li>
                                        <li><span><img src={Logo04} /></span>SHEMAROO TV</li>
                                        <li><span><img src={Logo05} /></span>DD NATIONAL</li>
                                        <li><span><img src={Logo06} /></span>E 24</li>
                                        <li><span><img src={Logo07} /></span>DD RETRO</li>
                                        <li><span><img src={Logo01} /></span>DD UP</li>
                                        <li><span><img src={Logo02} /></span>DD HIMACHAL PRADESH</li>
                                        <li><span><img src={Logo03} /></span>DD HISAR</li>
                                        <li><span><img src={Logo04} /></span>DD RAJASTHAN</li>
                                      </ul>
                                    </div>
                                    <div className='channelbox'>
                                      <h3>MOVIES PUNJABI  </h3>
                                      <ul>
                                        <li><span><img src={Logo01} /></span>DANGAL</li>
                                        <li><span><img src={Logo02} /></span>DANGAL 2</li>
                                        <li><span><img src={Logo03} /></span>SHEMAROO UMANG</li>
                                        <li><span><img src={Logo04} /></span>SHEMAROO TV</li>
                                        <li><span><img src={Logo05} /></span>DD NATIONAL</li>
                                        <li><span><img src={Logo06} /></span>E 24</li>
                                        <li><span><img src={Logo07} /></span>DD RETRO</li>
                                        <li><span><img src={Logo01} /></span>DD UP</li>
                                        <li><span><img src={Logo02} /></span>DD HIMACHAL PRADESH</li>
                                        <li><span><img src={Logo03} /></span>DD HISAR</li>
                                        <li><span><img src={Logo04} /></span>DD RAJASTHAN</li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className='booknow'>
                          <div className="wrap"><Link className="btn-11 fill-color" to='/book-now'><span>Get Now</span></Link></div>
                        </div>
                              </div>
                              </div>
                            {/* Channel Pack Popup */}
            </section>
        {/* <Contactform /> */}
        <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
        <Footer />
    </>
  )
}
