import React from "react";
import Header from "./components/Header";
import { Contactform } from "./components/Contactform";
import { Footer } from "./components/Footer";

export const LiveTv = () => {
  return (
    <>
      <section id="blacktext" className="p-0">
        <Header />
      </section>
      <section id="contentpage">
        <div className="contentpage">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="heading">
                  <h4>Live Tv</h4>
                </div>
                <div className="live_tv">
                <iframe src="https://restream.fastwaynetwork.com/8055f949-b77e-43e2-88bd-32fa417c6790.html"  autoplay  mute frameborder="no" scrolling="no" allowfullscreen="true" __idm_id__="5701633"></iframe>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Contactform />
      <Footer />
    </>
  );
};
