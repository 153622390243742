import React from 'react'

export const Effectarrow = (props) => {
  return (
    <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 164 606.66"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={82}
        x2={82}
        y1={0}
        y2={606.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ee3147" />
        <stop offset={1} stopColor="#0f62ac" />
      </linearGradient>
    </defs>
    <path
      d="M164 0v62.26C136.39 78 108.8 93.73 81.19 109.48L0 62.26V0l81.19 47.22C108.8 31.47 136.39 15.73 164 0ZM0 124.29v62.26l81.19 47.22c27.61-15.74 55.2-31.47 82.81-47.22v-62.26c-27.61 15.73-55.2 31.47-82.81 47.22L0 124.29Zm0 124.29v62.26l81.19 47.22c27.61-15.74 55.2-31.47 82.81-47.22v-62.26c-27.61 15.73-55.2 31.47-82.81 47.22L0 248.59Zm0 124.29v62.26l81.19 47.22c27.61-15.74 55.2-31.47 82.81-47.22v-62.26c-27.61 15.73-55.2 31.47-82.81 47.22L0 372.88Zm0 124.29v62.26l81.19 47.22c27.61-15.74 55.2-31.47 82.81-47.22v-62.26c-27.61 15.73-55.2 31.47-82.81 47.22L0 497.18Z"
      style={{
        fill: "url(#a)",
      }}
    />
  </svg>
    </>
  )
}
