import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

export const Ottplans = () => {

  var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

function createMarkup(content) {
    return { __html: decodeHTML(content) };
}

const [pagedata, setPagedata] = useState([]);
      useEffect(() => {
      let mounted = true;
      const loadData = async () => {
       const response = await axios.get(window.weburl + `api/plans.php?catID=18`)
       if(mounted){
           setPagedata(response.data);
       }
    }

    loadData();

    return () => {
        mounted = false;
      };
}, []);

console.log(pagedata);
if (pagedata.length===0) {
    console.log('no data');
    return null;
}

  return (
    <>
                   {pagedata.data ?
                                <div className='bundlestn'>                                          
{pagedata.data.map(getData => {
                                    const iconsArray = getData.icon_values.split(',');

                                            return <div className='bundlebox'>
                                                <h4 dangerouslySetInnerHTML={createMarkup(getData.pname)}></h4>
                                                {getData.icon_values ?
                                                <div className='apps'>
                                                  {iconsArray.map((iconsData, index) => {
                                                    const iconSeparate = iconsData.split('~');
                                                    return <img src={window.weburl + iconSeparate[1]} alt={iconSeparate[0]} title={iconSeparate[0]}/>
                                                  })}
                                                </div>
                                                : null}
                                                <div className='price ottprice'>
                                                <h6>{getData.price}/<small>{getData.ptype}</small></h6>
                                                </div>
                     <div className='btn-11 fill-color'>
                        <Link to={'/book-now/' + getData.plan_id}><span>Book Now </span></Link>
                    </div>
                                            </div>
                                            })}


                                            </div>
                                            : null}
    </>
  )
}
