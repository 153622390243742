import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const Numbers = () => {
      const [state, setState] = useState({ turnover: 1, workforce: 1, states: 1, cities: 1 });
      return (
            <>
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                        {({ isVisible }) => (
                              <div className="count" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.turnover === 1) ? 1 : 95} end={95} duration={3} onEnd={() => setState({ turnover: 7 })} /> : <CountUp start={1} end={95} duration={0} />} %
                                                <div className='labelcont'>
                                                <p>Market Share</p>
                                                </div>
                                          </div>

                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.workforce === 1) ? 1 : 3000} end={3000} duration={2} onEnd={() => setState({ workforce: 3000 })} /> : <CountUp start={1} end={3000} duration={0} />}+
                                                <div className='labelcont'>
                                                <p>Professionals</p>
                                                </div>
                                          </div>

                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                          {isVisible ? <CountUp start={(state.workforce===1) ? 1 : 1} end={2.5} duration={2.5} separator=" " decimals={1}  decimal="."  onEnd={()=> setState({ workforce:8 })} /> : <CountUp start={1} end={2.5} duration={0} />} 
                                           <p>Crore</p>
                                                <div className='labelcont'>
                                                <p>Viewers</p>
                                                </div>
                                          </div>

                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 8} end={8} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={8} duration={0} />}
                                               <div className='labelcont'>
                                               <p>STATES</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 300} end={300} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={300} duration={0} />}+
                                               <div className='labelcont'>
                                               <p>Cities</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 21000} end={21000} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={21000} duration={0} />}+ <p>KM</p>
                                               <div className='labelcont'>
                                               <p>Optic Fiber Cable</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 5} end={5} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={5} duration={0} />}<p>Million</p>+ 
                                               <div className='labelcont'>
                                               <p>DIGITAL CABLE SUBSCRIBERS</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 1} end={1} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={1} duration={0} />}<sup>st</sup>
                                               <div className='labelcont'>
                                               <p>UNDERGROUND INFRASTRUCTURE</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 100} end={100} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={100} duration={0} />}+ <p>CHANNELS</p> 
                                               <div className='labelcont'>
                                               <p> LARGEST LOCAL CONTENT</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 280000} end={280000} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={280000} duration={0} />}+ <p>KM</p>
                                               <div className='labelcont'>
                                               <p>OF EXTENSIVE FIBER NETWORK</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 400} end={400} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={400} duration={0} />}+ 
                                               <div className='labelcont'>
                                               <p>SEATER IN-HOUSE CALL CENTERS</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 10000} end={10000} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={10000} duration={0} />}+ <p>KM</p>
                                               <div className='labelcont'>
                                               <p>LOCAL SERVICE PARTNERS</p>
                                               </div>
                                          </div>
                                    </div>                                    
                              </div>

                        )}
                  </VisibilitySensor>
            </>
      )
}
