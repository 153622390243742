import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Entertainment } from './components/Entertainment'
import { Contactform } from './components/Contactform'

export const Entertainmentplan = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0 h-120'>                     
                    </div>
            </section>
            <section id='entertainment'>
          <div className='container'>
            <div className='row'>
                      <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <span>ENTERTAIMENT PLANS</span>
                        <h3>Your need. Your choice.</h3>
                      </div>
                      <Entertainment />
                    </div>
              </div>
      </section>
      <Contactform />
        <Footer />
    </>
   )
}
