import React, {useState, useEffect} from 'react'
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
// import Error from "./Error";
import '../src/css/style.css';
import '../src/css/header.css';
import '../src/css/footer.css';
import '../src/css/responsive.css';
import '../src/font/stylesheet.css'
import ScrollToTop from "./ScrollToTop";
import Home from "./Home";
import { Svg } from "./components/Svg";
// import { Androidtv } from "./Androidtv";
import { Iptv } from './Iptv';
import { Netplushd } from "./Netplushd";
import { Book } from "./Book";
import { Aboutus } from "./Aboutus";
import { Contactus } from "./Contactus";
import { Login } from "./Login";
import { Entertainmentplan } from "./Entertainmentplan";
import { Broadbandplan } from "./Broadbandplan";
import { Hdbox } from "./Hdbox";
import { Sdbox } from "./Sdbox";
import { Jujhar } from "./Jujhar";
import { Terms } from "./Terms"
import { Disclaimer } from './Disclaimer'
import { Privacy } from './Privacy'
import  {Search} from './Search'
import { Faqs } from './Faqs'
import { Channelpacks } from "./Channelpacks";
import { Management } from "./Management";
import { Technology } from "./Technology";
import { Locate } from "./Locate";
import { Fastway } from "./Fastway";
import { Download } from "./Download";
import { Bst } from "./Bst";
import { Refund } from './Refund';
import { Events } from './Events';
import { LiveTv } from './LiveTv';

const App = () => {

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/fastway-exclusive" component={Fastway}></Route>
          <Route exact path="/download" component={Download}></Route>
          <Route exact path="/fastway-hd-box" component={Hdbox}></Route>
          <Route exact path="/fastway-sd-box" component={Sdbox}></Route>
          <Route exact path="/ip-tv" component={Iptv}></Route>
          {/* <Route exact path="/digital-tv" component={Digitaltv}></Route> */}
          <Route exact path="/channel-packs" component={Channelpacks}></Route>
          <Route exact path="/channel-packs/:slug" component={Channelpacks}></Route>
          {/* <Route exact path="/digital-tv/" component={Error}></Route> */}
          <Route exact path="/digital-tv/:slug" component={Hdbox}></Route>
          <Route exact path="/our-management" component={Management}></Route>
          <Route exact path="/our-technology" component={Technology}></Route>
          <Route exact path="/fastway-hd-bundle" component={Netplushd}></Route>
          <Route exact path="/book-now/:slug" component={Book}></Route>
          <Route exact path="/entertainment-plans" component={Entertainmentplan}></Route>
          <Route exact path="/broadband-plans" component={Broadbandplan}></Route>
          <Route exact path="/about-fastway" component={Aboutus}></Route>
          <Route exact path="/about-jujhar" component={Jujhar}></Route>
          <Route exact path="/contact-us" component={Contactus}></Route>
          <Route exact path="/fastway-event" component={Events}></Route>
          <Route exact path="/locate-us" component={Locate}></Route>
          <Route exact path="/terms-conditions" component={Terms}></Route>
          <Route exact path="/disclaimer" component={Disclaimer}></Route>
          <Route exact path="/privacy-policy" component={Privacy}></Route>
          <Route exact path="/faqs" component={Faqs}></Route>
          <Route exact path="/live-tv" component={LiveTv}></Route>
          {/* <Route exact path="/login" component={Login}></Route> */}
          {/* <Route exact path="/search" component={Search}></Route> */}
          <Route path="/search" render={(props) => <Search key={props.location.search} />} />
          {/* <Route exact path="/svg" component={Svg}></Route> */}
          <Route exact path="/refund-policy" component={Refund}></Route>
          <Route exact path="/basic-service-tier" component={Bst}></Route>
          <Route component={Error}></Route>
        </Switch>
      </Router>
    </> 
  );
};

export default App;