import React from 'react'

export const Sdsvg = (props) => {
  return (
    <>
          <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 200.26 143.64"
    {...props}
  >
    <path d="M27.47 136.54h145.32v7.1H27.47zM200.26 126.11H0V0h200.26v126.11ZM7.1 119h186.06V7.1H7.1V119Z" />
    <path d="m65.07 67.46 4.41-1c1.78 5.33 5.62 7.99 11.54 7.99 3.09 0 5.42-.66 6.99-1.97s2.35-3.07 2.35-5.27c0-1.67-.6-3.01-1.79-4.02-1.19-1.01-2.57-1.71-4.14-2.1l-7.82-1.93c-2.02-.52-3.68-1.14-4.97-1.88-1.29-.74-2.38-1.87-3.28-3.4-.9-1.53-1.35-3.28-1.35-5.25 0-3.27 1.23-5.87 3.69-7.81 2.46-1.94 5.62-2.91 9.48-2.91 7.1 0 11.77 3.22 14 9.65l-4.44 1.32c-.52-2.29-1.73-4.02-3.64-5.2-1.91-1.17-3.94-1.76-6.1-1.76s-4.04.57-5.71 1.7-2.51 2.73-2.51 4.8c0 2.9 2.12 4.85 6.35 5.87L85.44 56c6.68 1.6 10.02 5.2 10.02 10.8 0 3.38-1.26 6.19-3.76 8.43-2.51 2.24-6.05 3.37-10.61 3.37-8.09 0-13.44-3.71-16.03-11.14ZM102.75 77.8V38.72h13.11c5.52 0 9.81 1.88 12.89 5.64 3.07 3.76 4.61 8.36 4.61 13.8s-1.5 10.17-4.5 13.96c-3 3.79-6.8 5.68-11.4 5.68h-14.72Zm4.9-4.15h7.93c4.2 0 7.39-1.45 9.56-4.35 2.18-2.9 3.26-6.73 3.26-11.48 0-3.85-1.04-7.32-3.11-10.39s-5.31-4.61-9.72-4.61h-7.93v30.84Z" />
  </svg>
    </>
  )
}
