import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import { Form } from "./components/Form";
import { Card } from "./components/Card";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import search_icon from "./images/search_btn.png";
import { Svg } from "./components/Svg";
import axios from "axios";

export const Search = () => {
  const ITEMS_PER_PAGE = 15; // Number of search results to display per page
  const [data, setData] = useState([]);
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // const apiUrl = `http://127.0.0.1/search_result_api/get_response.php?s=${keyword}`;
    setKeyword(new URLSearchParams(location.search).get("keyword"));

    const fetchData = async () => {
      try {
        const apiUrl = window.weburl + `api/get_response.php?s=${keyword}`;
        const response = await axios.get(apiUrl); // Use axios.get instead of fetch
        const jsonData = response.data;
        console.log("data from the api", jsonData);
        setResponse(jsonData.response)
        console.log("api responde", jsonData);
        setData(jsonData.searchprogramme?.programme || []);
        setIsLoading(false);
        // setIsDataFetched(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
        // setIsDataFetched(true);
      }
    };
    fetchData();
    console.log("This is rponse state", response)
    // -=--------------------------------------for checking the loader  ------------------------------------------------
    //  const timeoutId = setTimeout(fetchData, 10000000); // Set timeout for 12 seconds (12000 milliseconds)

    // // Cleanup function to clear the timeout if the component unmounts before the timeout is reached
    // return () => clearTimeout(timeoutId);
  }, [keyword, location.search]);

  // Function to update the keyword state from the input value
  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };

  // Function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Get the current page's data
  const currentPageData = data.slice(startIndex, endIndex);

  return (
    <>
      <section id="blacktext" className="p-0">
        <Header />
      </section>
      <section id="contentpage">
        <div className="contentpage __search">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="heading">
                  <h4>Your Search: {keyword}</h4>
                </div>
                <div className="search_cnt header_white">
                  <li className="Srch_btn">
                    <form>
                      <input
                        type="text"
                        name="keyword"
                        id="input_cnt"
                        placeholder="Search for Movies, TV Shows, Channels & Actors"
                        onChange={handleInputChange}

                        autoFocus
                      />
                      <button type="submit">
                        <img src={search_icon} alt="" />
                      </button>
                    </form>
                  </li>
                  <div className="search_cnt_data">
                    {isLoading ? (
                      <div className="loading_screen">
                        <div className="loading_loader"></div>
                      </div>
                    ) : response ? (
                      <p>{response.message}</p>
                    ) : (
                      currentPageData.map((item) => (
                        <Card
                          key={item.programid}
                          isLoading={isLoading}
                          Name={item.programmename}
                          Image={item.imagefilepath}
                          logoImage={item.logofileurl}
                          ChannelNumber={item.lcn}
                          BoradCastTime={item.timestring}
                        />
                      ))
                    )}
                  </div>
                  {totalPages > 1 && (
                    <ul className="pagination" id="pagination">
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          key={index}
                          className={`pagination-item ${index + 1 === currentPage ? "active" : ""
                            }`}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  );
};
