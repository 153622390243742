import React from 'react'
import Slider from 'react-slick';
import Plan_bg from "../images/Plan_Bg.webp"

export const Broadband = () => {
    var settings3 = {
        dots: false,
        navigation: true,
        loop: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1340,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      };
  return (
    <>
     <div className='planslider'>
                        <Slider {...settings3}>
                          <div>
                            <div className='plansitem'>
                              <div className='ptag'>
                                <h5>silver</h5>
                              </div>
                              <div className='image_plans_div'>
                                <div className="image_plan">
                                  <img src={Plan_bg} alt="Plan Image" />
                                </div>
                                <p>100 <span>Mbps</span></p>
                              </div>
                              <div className='price'>
                                <div className='sprice'>
                                  <h6>499</h6>
                                </div>
                                <ul>
                                  <li>Unlimited Data</li>
                                  <li>Unlimited Calls</li>
                                  <li>1 Month Validity</li>
                                </ul>
                                <div className='buttnprop'>
                                  <span>Book Now</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='plansitem'>
                              <div className='ptag'>
                                <h5>Gold</h5>
                              </div>
                              <div className='image_plans_div'>
                                <div className="image_plan">
                                  <img src={Plan_bg} alt="Plan Image" />
                                </div>
                                <p>150 <span>Mbps</span></p>
                              </div>
                              <div className='price'>
                                <div className='sprice'>
                                  <h6>599</h6>
                                </div>
                                <ul>
                                  <li>Unlimited Data</li>
                                  <li>Unlimited Calls</li>
                                  <li>1 Month Validity</li>
                                </ul>
                                <div className='buttnprop'>
                                  <span>Book Now</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='plansitem'>
                              <div className='ptag'>
                                <h5>Diamond</h5>
                              </div>
                              <div className='image_plans_div'>
                                <div className="image_plan">
                                  <img src={Plan_bg} alt="Plan Image" />
                                </div>
                                <p>200 <span>Mbps</span></p>
                              </div>
                              <div className='price'>
                                <div className='sprice'>
                                  <h6>699</h6>
                                </div>
                                <ul>
                                  <li>Unlimited Data</li>
                                  <li>Unlimited Calls</li>
                                  <li>1 Month Validity</li>
                                </ul>
                                <div className='buttnprop'>
                                  <span>Book Now</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className='plansitem'>
                              <div className='ptag'>
                                <h5>Platinum</h5>
                              </div>
                              <div className='image_plans_div'>
                                <div className="image_plan">
                                  <img src={Plan_bg} alt="Plan Image" />
                                </div>
                                <p>250 <span>mbps</span></p>
                              </div>
                              <div className='price'>
                                <div className='sprice'>
                                  <h6>799</h6>
                                </div>
                                <ul>
                                  <li>Unlimited Data</li>
                                  <li>Unlimited Calls</li>
                                  <li>1 Month Validity</li>
                                </ul>
                                <div className='buttnprop'>
                                  <span>Book Now</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
    </>
  )
}
