import React from 'react'
import Slider from 'react-slick';
import App1 from '../images/Ott03.webp'
import App2 from '../images/Ott04.webp'
import App3 from '../images/Ott05.webp'
import App4 from '../images/Ott06.webp'
import App5 from '../images/Ott07.webp'
import App6 from '../images/Ott08.webp'
import App7 from '../images/Ott11.webp'
import App8 from '../images/Ott15.webp'
import App9 from '../images/Ott12.webp'
import App10 from '../images/Ott14.webp'
import App11 from '../images/0tt01.webp'
import App12 from '../images/Ott02.webp'
import App13 from '../images/Ott09.webp'
import App14 from '../images/Ott10.webp'

export const Ottslider = () => {
    var settings2 = {
        dots: false,
        navigation: true,
        loop: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 3000,
        cssEase: "linear"
      };
  return (
    <>
      <div className='ottslider'>
                                <Slider {...settings2}>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App1} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                <div className='ottimg'>
                                  <img src={App2} alt='Sony Live'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App3} alt='Disney Hotstar'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App4} alt='History'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App5} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App6} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App7} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App8} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App9} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App10} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App11} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App12} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App13} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                  <div>
                                  <div className='ottimg'>
                                  <img src={App14} alt='Amazon Prime'/>                              
                                </div>
                                  </div>
                                </Slider>
                                </div>
    </>
  )
}
