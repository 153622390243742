import React from 'react'
import Aboutus from '../images/about-jujhar-group.webp'
import { Effectarrow } from '../components/Effectarrow';

export const Jujharcont = () => {
  return (
    <>
          <section id='aboutus'>
        <div className='aboutus'>
        <div className='leftarrow '>
              <Effectarrow />
          </div>          
          <div className='rightarrow '>
          <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='jabtout'>
                  <div className='aboutimg' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <img src={Aboutus} alt='About Jujhar Group' />
                  </div>
                  <div className='jabcont' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='title'>
                      <span>About Us</span>
                      <h3>Jujhar group over 30 years of excellence</h3>
                    </div>
                    <p>The Jujhar Group is a multidimensional conglomerate headquartered in Ludhiana, Punjab. Headed by Chairman S Gurdeep Singh, a pioneer of the Indian Transport industry, the Jujhar Group has an enviable market leadership position in its many verticals and has an impressive customer base in growing sectors namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure, Hospitality & Healthcare.</p>
                    <div className='booknow justify-content-start'>
                              <div className="wrap"><a className="btn-11 fill-color" href='https://www.jujhargroup.com/' target='_blank' rel='noreferrer'><span>Know More</span></a></div>
                              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
