import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import img01 from "./images/banner_04.webp";
import noimage from "./images/no-image.webp";
import { Form } from "./components/Form";
import Slider from "react-slick";
import { Effectarrow } from "./components/Effectarrow";
import axios from "axios";

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Fastway = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(window.weburl + "api/get_channel_data.php");
      const data = await response.json();
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = data.filter(
      (row) =>
        (row.title && row.title.toLowerCase().includes(term.toLowerCase())) ||
        (row.cat_name &&
          row.cat_name.toLowerCase().includes(term.toLowerCase())) ||
        (row.specify && row.specify.toLowerCase().includes(term.toLowerCase()))
    );
    setFilteredData(filtered);
  };

  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    arrows: false,
    cssEase: "linear",
  };
  return (
    <>
      <Header />
      <section
        id="banner"
        className="p-0 grey-color internal"
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="banner">
          <div className="leftarrow white">
            <Effectarrow />
          </div>
          <div className="rightarrow white">
            <Effectarrow />
          </div>
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="home-slider">
                  <Slider {...settings}>
                    <div>
                      <div className="sliderrow align-items-center">
                        <div className="slideimg">
                          <img src={img01} alt="Banner Netplus" />
                        </div>
                        <div className="slidecont">
                          <div className="circle01">
                            <div className="mngcircle">
                              <div className="circlimg">
                                <h2>
                                  Local Taste. <br /> Exclusive for You.{" "}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="filter" className="grey-color">
        <Form cat="all" />
      </section>
      <section id="channels">
        <div className="channels">
          <div className="leftarrow">
            <Effectarrow />
          </div>
          <div className="rightarrow">
            <Effectarrow />
          </div>
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title">
                  <h3>Fastway Local Channels</h3>
                </div>
                <div className="searchstn">
                  <div className="searchbox">
                    <input
                      type="text"
                      placeholder="Search by channel name, number, etc"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                    <button type="submit">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div className="execlusivechannel">
                    <div class="exclusivehead">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Channel Name</th>
                            <th scope="col">LCN</th>
                            <th scope="col">Genre</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {filteredData && filteredData.map((cData) => ( */}
                          {filteredData.map((cData) => (
                            <tr key={cData.sno}>
                              <th scope="row">
                                <div className="channelname">
                                  <span>
                                    <img
                                      src={
                                        cData.cicon != window.weburl
                                          ? cData.cicon
                                          : noimage
                                      }
                                    />
                                  </span>
                                  <p
                                    dangerouslySetInnerHTML={createMarkup(
                                      cData.title
                                    )}
                                  ></p>
                                </div>
                              </th>
                              <td
                                dangerouslySetInnerHTML={createMarkup(
                                  cData.specify
                                )}
                              ></td>
                              <td
                                dangerouslySetInnerHTML={createMarkup(
                                  cData.cat_name
                                )}
                              ></td>
                            </tr>
                          ))}
                          {/* {selectedchannels.map (cData => (
    <tr>
      <th scope="row"><span><img src={cData.cicon} /></span><span dangerouslySetInnerHTML={createMarkup(cData.title)}></span></th>
      <td dangerouslySetInnerHTML={createMarkup(cData.specify)}></td>
      <td dangerouslySetInnerHTML={createMarkup(cData.cat_name)}></td>
    </tr>
    ))} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  );
};
