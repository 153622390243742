import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import { Form } from "./components/Form";
import Slider from 'react-slick'
import banner from './images/technology-banner.webp';
import Img01 from './images/Techno-01.webp'
import Img02 from './images/Techno-02.webp'
import Img03 from './images/Techno-03.webp'
import Img04 from './images/Techno-04.webp'
import Img05 from './images/Techno-05.webp'
import Img06 from './images/Techno-06.webp'


import { Effectarrow } from './components/Effectarrow';

export const Technology = () => {
  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    arrows: false,
    cssEase: "linear"
  };
  return (
    <>
      <Header />
      <section id='banner' className='p-0 internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <div className='banner'>
          <div className='leftarrow white'>
            <Effectarrow />
          </div>
          <div className='rightarrow white'>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='home-slider'>
                  <Slider {...settings}>
                    <div>
                      <div className='sliderrow align-items-center'>
                        <div className='slideimg'>
                          <img src={banner} alt='Banner Netplus' />
                        </div>
                        <div className='slidecont'>
                          <div className='circle01'>
                            <div className='mngcircle'>
                              <div className='circlimg'>
                                <h2>Pioneering the Next Era of Technological Marvels</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='smartcity'>
        <div className='smartcity'>
          <div className='leftarrow '>
            <Effectarrow />
          </div>
          <div className='rightarrow '>
            <Effectarrow />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">

                  <h3>Technology</h3>
                  <p>Fastway Transmissions Private Limited is fastest growing Multi System Operators (MSO) in North India. Fastway services are available in majority of areas in Punjab, Himachal Pradesh, Haryana, U.P, Rajasthan and Union Territory of Chandigarh. Fastway provides Digital Cable TV services in North India.</p>
                </div>
                <div className='protechnology'>
                  <div className='technobox' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='technoimg'>
                      <img src={Img01} alt='' />
                    </div>
                    <div className='technocont'>
                      <h4>CISCO SYSTEMS INTERNATIONAL B.V</h4>
                      <p>CISCO is biggest supplier of networking equipments worldwide. Fastway has tied up with CISCO for providing IP Technology, Conditional Access System (CAS) and STB’s.</p>
                    </div>
                  </div>
                  <div className='technobox' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='technoimg'>
                      <img src={Img02} alt='' />
                    </div>
                    <div className='technocont'>
                      <h4>CONAX</h4>
                      <p>CONAX was among the pioneers defining the broadcast security architecture now used all over the world, and in using smart cards as the user security module. Fastway has procured CAS from CONAX.</p>
                    </div>
                  </div>
                  <div className='technobox' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='technoimg'>
                      <img src={Img03} alt='' />
                    </div>
                    <div className='technocont'>
                      <h4>GOSPELL</h4>
                      <p>Gospell is a emerging leader in digital tv market and trending in indian stb market with its superior quality HD set top box.Fastway is using Gospell CAS with its HD stb to provide our customers a better HD3 experience.</p>
                    </div>
                  </div>
                  <div className='technobox' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='technoimg'>
                      <img src={Img04} alt='' />
                    </div>
                    <div className='technocont'>
                      <h4>ORACLE BRM</h4>
                      <p>BRM Software is a leading provider of subscriber & billing management and also capable of providing enterprise resource & planning solutions.Oracle being technology partner have given Fastway an edge in efficiency, cost reduction and continuous value addition to the company's service offerings.</p>
                    </div>
                  </div>
                  <div className='technobox' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='technoimg'>
                      <img src={Img05} alt='' />
                    </div>
                    <div className='technocont'>
                      <h4>Digital Telemedia Co. Ltd</h4>
                      <p>Digital Telemedia Co. Ltd. is one of the most professional OEM/ODM company specialized in the research & development, manufacturing and marketing of DVB products (DVB-S/C/T), Cable TV Equipment, Multi-media Products, IPTV STB, HDTV STB, satellite accessories and other digital equipment. Fastway has procured STB from Digital Telemedia Co. Ltd.</p>
                    </div>
                  </div>
                  <div className='technobox' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='technoimg'>
                      <img src={Img06} alt='' />
                    </div>
                    <div className='technocont'>
                      <h4>EMC</h4>
                      <p>EMC is a global leader in enabling businesses and service providers to transform their operations and deliver information technology as a service (ITaaS).</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
        <div className='title'>
          <h3>Get your Connection Now!</h3>
        </div>
        <Form cat='cp' />
      </section>
      <Footer />
    </>
  )
}
