import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'

export const Terms = () => {
    return (
    <>
            <section id='blacktext' className='p-0'>
                <Header />
            </section>
            <section id='contentpage'>
                <div className='contentpage'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading'>
                                    <h4>TERMS & CONDITIONS</h4>
                                </div>
                                <p class="headtext">
                                    All definition/interpretation are to be construed and understood as per the
                                    provisions of Cable Television Network (Regulations) Act, 1995 & Rules 1994, Amended Act,
                                    2012 and amendment made from time to time set out herein:
                                </p>
                                <ul class="desclist">
                                    <li>

                                        The Applicant shall make the as per the scheme of prepaid or post paid
                                        selected by him and in case of new subscriber it should be accompanied by duly
                                        completed and signed application form.
                                    </li>
                                    <li>

                                        The STB and the accessories shall be solely used for availing services
                                        provided by Fastway Transmissions Private Limited “FTPL” and shall not be used for availing
                                        services of any other Cable Network.
                                    </li>
                                    <li>

                                        The Channel/package fees as prescribed from time to time, for each
                                        channel/packages selected by the subscriber, shall be paid in advance.
                                    </li>
                                    <li>

                                        The channel/package rates may change according to the applicable rules
                                        and regulations.
                                    </li>
                                    <li>

                                        Any channel/package will have to be subscribed for a minimum period of
                                        6 months as per TRAI guidelines.
                                    </li>
                                    <li>

                                        The subscriber shall take adequate care of the STB while availing the
                                        services of cable/ broadband.Any willful default or damage to STB will be billed to the
                                        account of the subscriber.
                                    </li>
                                    <li>

                                        The subscriber shall not, without the prior authorization of FTPL remove,
                                        transfer or otherwise relocate the STB from the original place of installation.
                                    </li>
                                        <li>

                                            In case STB is lost or damaged on account of theft, burglary or by any
                                            other accident, the subscriber will file a police complaint forthwith and notify FTPL of
                                            such loss immediately, with copy of complaint.
                                        </li>
                                        <li>

                                            In case of nonpayment/delay in payment, FTPL reserves the right to cancel
                                            the subscription and to discontinue the services. In such cases, the subscriber will return
                                            the STB without any objection and in good working condition. FTPL shall have the right
                                            to recover the balance dues and price of STB in case it is not returned or is damaged or not
                                            working properly.
                                        </li>

                                        <li>

                                            The channel/packages fees, as prescribed from time to time for each
                                            channel/packages selected shall be paid in advance, either to FTPL or to the authorized
                                            franchisee as the case may be.
                                        </li>

                                        <li>

                                            The subscriber shall, in advance, notify change of address/contact
                                            number to FTPL or its authorized representative.
                                        </li>
                                        <li>

                                            For reconnection/activation of the service earlier disconnected for any
                                            reason, the subscriber shall be liable to pay reconnection charges as prescribed from time
                                            to time as per regulations.
                                        </li>
                                        <li>

                                            Cable services in respect of channels/packages shall be available to
                                            the subscriber, subject to Force Majeure conditions including but not limited to act of God,
                                            fires, strikes, embargoes, war insurrection, riots and other causes beyond the reasonable
                                            control FTPL including atmospheric/topographical hindrances.
                                        </li>
                                        <li>

                                            The smart card and STB shall always remain property of FTPL.
                                            The warranty of one year will cover the smart card, STB components, adaptor and remote
                                            control device, unless physical or willful damage has been caused to it. In case of physical
                                            or willful damage warranty will not be provided.
                                        </li>
                                        <li>

                                            Subscribers are not authorized to install any Gadgets on STB without
                                            FTPL’s prior written permission.
                                        </li>
                                        <li>

                                            Subscriber data can be used for marketing/promotional purposes by
                                            FTPL or its associates.
                                        </li>
                                        
                                            <li>

                                                All prices are exclusive of taxes.
                                            </li>
                                            
                                                <li>

                                                    For more than 3 television please use a different Package Application
                                                    Form. For every STB separate application form shall be used.
                                                </li>
                                                
                                                    <li>

                                                        Packages are applicable from 1st April 2013.
                                                    </li>
                                                    
                                                        <li>

                                                            Free Add-Ons of the season are available only for pay packages and are
                                                            subject to revision as per company policy. The same can be reviewed from time to time on
                                                            www.fastway.in
                                                        </li>
                                                        
                                                            <li>

                                                                For choosing available packages logon to company’s website.
                                                                Different packages can be selected for different T.V. sets and a separate Package
                                                                Application Form shall be filled for each T.V.
                                                            </li>
                                                            <li>

                                                                These packages are only for FTPL Customers.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <Contactform />
                                    <Footer />
                                </>
                                )
}
