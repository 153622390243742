import React from "react";
import Header from "./components/Header";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import { Form } from "./components/Form";
import Icon01 from "./images/sd-icon.webp";
import Icon02 from "./images/pay.webp";
import Icon03 from "./images/hd-icon.webp";
import Icon04 from "./images/subscribe.webp";
import Icon05 from "./images/Terms and Conditions.webp";
import Icon06 from "./images/BST Bouqet.webp";
import Icon07 from "./images/BST.webp";
import Icon08 from "./images/Call Center.webp";
import Icon09 from "./images/Disclaimer.webp";
import Icon10 from "./images/FW Favourite.webp";
import Icon11 from "./images/Gold Plus.webp";
import Icon12 from "./images/Gold.webp";
import Icon13 from "./images/Hd Plus.webp";
import Icon14 from "./images/HD-1.webp";
import Icon15 from "./images/NCF Policy.webp";
import Icon16 from "./images/Platinum.webp";
import Icon17 from "./images/Privacy.webp";
import Icon18 from "./images/Refund.webp";
import Icon19 from "./images/Request Online.webp";
import Icon20 from "./images/Silver.webp";
import Icon21 from "./images/STB Policy.webp";
import BST_NCF from "./images/BST-NCF.pdf";
import STB_OPTION from "./images/STB-OPTION.pdf";
import ALA_CARTE_CHANNEL from "./images/ALA-CARTE CHANNEL.pdf";
import Broadcaster from "./images/Broadcaster.pdf";
import s_bst_plus from "./images/s-bst plus.pdf";
import s_silver from "./images/s-silver.pdf";
import s_gold_plus from "./images/s-gold-plus.pdf";
import s_platinum from "./images/s-platinum.pdf";
import s_hd from "./images/s-hd.pdf";
import BST from "./images/BST.pdf";
import s_hd_plus from "./images/s-hd-plus.pdf";
import s_bouquet from "./images/s_bouquet.webp";
import Network_Capacity_Fee from "./images/Network Capacity Fee.pdf";

export const Faqs = () => {
  return (
    <>
      <section id="blacktext" className="p-0">
        <Header />
      </section>
      <section id="download" className="p-0">
        <div className="download faqstn">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title">
                  <span>FAQ's</span>
                  <h3>Consumer Corner</h3>
                </div>
                <div className="downloadstn">
                  <div className="downloadbox">
                    <a target="_blank" href={BST_NCF}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon01} />
                        </span>{" "}
                        <h3>FTA Channnel List</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={ALA_CARTE_CHANNEL}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon02} />
                        </span>
                        <h3>Pay Channel Rates (Alacarte) </h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={Broadcaster}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon03} />
                        </span>
                        <h3>Broadcaster Bouquet</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon04} />
                        </span>
                        <h3> Subscriber/login</h3>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="heading">
                  <h4> 
                  Suggestive Bouquet                 
                  </h4>
                </div>
                <div className="downloadstn">
                  <div className="downloadbox">
                    <NavLink target="_blank" to="/basic-service-tier" >
                      <div className="consumerbox">
                        <span>
                          <img src={Icon07} />
                        </span>
                        <h3>BST</h3>
                      </div>
                    </NavLink>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank " href={s_bst_plus}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon10} />
                        </span>
                        <h3>FW Favourite</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank " href={BST_NCF}>
                      <div className="consumerbox">
                        <span>
                          <img src={s_bouquet} />
                        </span>
                        <h3>Suggestive Bouquet</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={s_silver}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon20} />
                        </span>
                        <h3>Silver</h3>
                      </div>
                    </a>
                  </div>
                  {/* <div className="downloadbox">
                    <a target="_blank">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon12} />
                        </span>
                        <h3> Gold</h3>
                      </div>
                    </a>
                  </div> */}
                  <div className="downloadbox">
                    <a target="_blank" href={s_gold_plus}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon11} />
                        </span>
                        <h3> Gold Plus</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={s_platinum}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon16} />
                        </span>
                        <h3>Platinum</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={s_hd}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon14} />
                        </span>
                        <h3>HD</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={s_hd_plus}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon13} />
                        </span>
                        <h3>HD Plus</h3>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="heading">
                  <h4>Subscribing to Channels</h4>
                </div>
                <div className="downloadstn">
                  <div className="downloadbox">
                    <NavLink to='/contact-us'>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon08} />
                        </span>
                        <h3>Call Center</h3>
                      </div>
                    </NavLink>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href="http://fwpackages.myfastway.in/index.php">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon19} />
                        </span>
                        <h3>Request Online</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={Network_Capacity_Fee}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon15} />
                        </span>
                        <h3>NCF Policy</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={BST_NCF }>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon06} />
                        </span>
                        <h3> BST Bouquet</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a target="_blank" href={STB_OPTION}>
                      <div className="consumerbox">
                        <span>
                          <img src={Icon21} />
                        </span>
                        <h3> STB Policy</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a  href="/terms-conditions">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon05} />
                        </span>
                        <h3>Terms & Conditions</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a href="/privacy-policy">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon17} />
                        </span>
                        <h3>Privacy</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a  href="/refund-policy">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon18} />
                        </span>
                        <h3>Refund</h3>
                      </div>
                    </a>
                  </div>
                  <div className="downloadbox">
                    <a  href="/disclaimer">
                      <div className="consumerbox">
                        <span>
                          <img src={Icon09} />
                        </span>
                        <h3>Disclaimer</h3>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
      <Footer />
    </>
  );
};
