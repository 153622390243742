import React from 'react'

export const Downloadsvg = (props) => {
  return (
    <>
       <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 109.33 122.88",
    }}
    viewBox="0 0 109.33 122.88"
    {...props}
  >
    <path
      d="M82.28 105.33h-10.8V90.47H56.14v14.86H44.99l18.64 17.54 18.65-17.54zm20.12-71.38H83.61c-1.98 0-4.73-1.06-6.03-2.36-1.3-1.3-2.09-3.65-2.09-5.61V6.95H25.83c-.16 0-.29.08-.37.16-.14.09-.18.21-.18.38v87.73c0 .12.09.29.16.37.09.13.26.16.38.16h12.96a27.52 27.52 0 0 0-2.23 6.91H23.7a5.22 5.22 0 0 1-3.73-1.55 5.218 5.218 0 0 1-1.54-3.73V5.32c0-1.46.59-2.76 1.54-3.73A5.29 5.29 0 0 1 23.7.04h55.31c.12-.04.24-.04.37-.04.58 0 1.18.25 1.59.62h.08c.09.05.13.09.21.16l27.27 27.6c.46.46.8 1.09.8 1.8 0 .21-.04.37-.08.6v66.61c0 1.46-.59 2.77-1.55 3.73-.97.96-2.27 1.55-3.73 1.55H90c-.44-2.43-1.19-4.75-2.23-6.91h14.07c.16 0 .16-.09.25-.16.13-.09.3-.26.3-.37 0-23.45.01-37.84.01-61.28zm-20.68-8.52V8.12l19.49 19.74H84.15c-.67 0-1.26-.29-1.72-.71-.42-.42-.71-1.05-.71-1.72z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#262626",
      }}
    />
    <path
      d="M0 38.51h88.9v42.71H0V38.51z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "red",
      }}
    />
    <path
      d="M12.07 48.62h11.77c2.56 0 4.48.61 5.75 1.82 1.27 1.22 1.91 2.94 1.91 5.18 0 2.3-.69 4.1-2.09 5.4-1.39 1.3-3.51 1.94-6.37 1.94h-3.87v8.46h-7.11v-22.8h.01zm7.11 9.72h1.73c1.37 0 2.33-.23 2.87-.71.55-.47.83-1.07.83-1.81 0-.72-.24-1.33-.72-1.82-.47-.5-1.38-.75-2.7-.75h-2.02v5.09h.01zm15.81-9.72H45.5c2.07 0 3.75.28 5.02.84 1.27.56 2.32 1.36 3.16 2.41.83 1.05 1.43 2.27 1.81 3.66.37 1.39.56 2.86.56 4.42 0 2.44-.28 4.33-.84 5.67-.55 1.34-1.33 2.47-2.32 3.38-.99.91-2.05 1.51-3.18 1.81-1.55.41-2.96.62-4.22.62h-10.5V48.62zm7.07 5.17v12.45h1.73c1.48 0 2.53-.16 3.16-.49.62-.32 1.11-.9 1.47-1.71.35-.81.53-2.13.53-3.96 0-2.42-.39-4.07-1.18-4.96-.79-.89-2.11-1.34-3.94-1.34h-1.77v.01zm17.43-5.17h17.5v4.9H66.6v3.98h8.87v4.6H66.6v9.32h-7.11v-22.8z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
    </>
  )
}
