import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import { Form } from "./components/Form";
import Slider from 'react-slick'
import img01 from './images/fastway-management.webp';
import Prem from './images/Prem-Ojha.webp'
import Gurdeep from './images/S-Gurdeep-Singh.webp'
import { Effectarrow } from './components/Effectarrow';

export const Management = () => {
    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };
    return (
        <>
            <Header />
            <section id='banner' className='p-0 internal' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner'>
                    <div className='leftarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='home-slider'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='sliderrow align-items-center'>
                                                <div className='slideimg'>
                                                    <img src={img01} alt='Banner Netplus' />
                                                </div>
                                                <div className='slidecont'>
                                                    <div className='circle01'>
                                                        <div className='mngcircle'>
                                                            <div className='circlimg'>
                                                                <h2>Making people smile.<br /> Since 1990.</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="leadershipdetails">
                <div className="leadershipdetails">
                    <div className='leftarrow '>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow '>
                        <Effectarrow />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="web-container">
                                <div className='title'>
                                    <span>Our Management</span>
                                </div>
                                <div className="leaddetails">
                                    <h1>S Gurdeep Singh</h1>
                                    <h2><span>Founder and Chairman</span> <span>Jujhar Group</span></h2>
                                    <div className="leaddetail">
                                        <img src={Gurdeep} alt="S-Gurdeep-Singh" title="S-Gurdeep-Singh" width="200" height="200" />
                                        <p>S Gurdeep Singh is a natural leader, committed towards driving growth and change. Having graduated from the Government College Ludhiana in 1985, he joined his family transport business soon after. At a very early stage, he realized the need for a professional logistics service that operated with uncompromised standards and precision, and in 1990, he founded Jujhar Constructions & Travels Pvt. Ltd.</p>
                                        <p>A true visionary across multiple verticals, he recognized the value of high-quality broadcasting and expanded the company into Multi-Satellite Operations by establishing Fastway Transmissions, thereby revolutionizing the way Punjab watches TV. He also pioneered the setting up of an expansive optic fiber cable across Punjab and facilitated the explosion of high speed broadband internet with a multitude of allied smart applications with the introduction of Netplus Broadband.</p>
                                        <p>He eventually also found his calling in Real Estate, and ventured into the Jujhar Group’s large-scale residential and commercial projects.</p>
                                        <p>Under his leadership, the group has witnessed unprecedented progress and scaled up to expand into a multitude of high-growth industries, with operations across 8 states in North India. Currently, he resides on the board of over 20 companies under the Jujhar Group banner.</p>
                                    </div>
                                </div>
                                <div className="leaddetails">
                                    <h1>Dr. Prem Ojha</h1>
                                    <h2><span>CEO</span> <span>Netplus and Fastway Cables</span></h2>
                                    <div className="leaddetail">
                                        <img src={Prem} alt="Prem Ojha" title="Prem Ojha" width="200" height="200" />
                                        <p>
                                            Prem Ojha brings with him a passion for excellence in the telecom sector, with a vivid experience of over three decades. He is an authority in the Internet services domain and has many firsts to his credit.
                                            He has a very successful track record of leading organizations to be best-in-class and achieving a dominant market leadership position.
                                        </p>
                                        <p>Prior to joining Jujhar Group in 2017, he served as COO at Videocon Telecommunication and the Regional Head at Tata Communications.</p>
                                        <p>After his B. Tech. in Electronics and Communications, Prem Ojha did his Masters in Marketing, followed by PhD in Management.</p>
                                        <p>
                                            A harbinger in the evolution & growth of broadband services, he is credited with the successful launch of next generation converged services including FTTH based High Speed Broadband, Smart Telephony, ATV
                                            & OTT Services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Contactform /> */}
            <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
            <Footer />
        </>
    )
}
