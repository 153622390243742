import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'

export const Locate = () => {
  return (
   <>
  <section id='blacktext' className='p-0'>
        <Header />
        </section>
    
   </>
  )
}
